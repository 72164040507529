// framerates: [".75", "1", "20", "23.976", "24", "25", "27", "29.97", "30", "35", "38", "40", "41", "45", "48", "50", "55", "59.94", "60", "65", "70", "72", "75", "80", "90", "95", "96", "100", "105", "120"]},
export const ARRICameras = {
    "ALEXA  35": {
        brand: "ARRI",
        resolutions: {
            "4.6K 3:2 Open Gate (4608 x 3164)": { 
                dimensions: [4608, 3164], 
                codecs: {
                    "ARRIRAW": {framerates: [".75", "1", "20", "23.976", "24", "25", "27", "29.97", "30", "35", "38", "40", "41", "45", "48", "50", "55", "59.94", "60", "65", "70", "72", "75"]},
                    "ARRIRAW  HDE": {framerates: [".75", "1", "20", "23.976", "24", "25", "27", "29.97", "30", "35", "38", "40", "41", "45", "48", "50", "55", "59.94", "60", "65", "70", "72", "75"]},
                    "Apple ProRes 4444 XQ": {framerates: [".75", "1", "20", "23.976", "24", "25", "29.97", "30", "35", "38", "40", "41", "45", "48", "50", "55", "59.94", "60"]},
                    "Apple ProRes 4444": {framerates: [".75", "1", "20", "23.976", "24", "25", "29.97", "30", "35", "38", "40", "41", "45", "48", "50", "55", "59.94", "60"]},
                    "Apple ProRes 422 HQ": {framerates: [".75", "1", "20", "23.976", "24", "25", "29.97", "30", "35", "38", "40", "41", "45", "48", "50", "55", "59.94", "60"]}
                }
            },
            "4.6K 16:9 (4608 x 2592)": { 
                dimensions: [4608, 2592], 
                codecs: {
                    "ARRIRAW": {framerates: [".75", "1", "20", "23.976", "24", "25", "27", "29.97", "30", "35", "38", "40", "41", "45", "48", "50", "55", "59.94", "60", "65", "70", "72", "75"]},
                    "ARRIRAW  HDE": {framerates: [".75", "1", "20", "23.976", "24", "25", "27", "29.97", "30", "35", "38", "40", "41", "45", "48", "50", "55", "59.94", "60", "65", "70", "72", "75"]},
                    "Apple ProRes 4444 XQ": {framerates: [".75", "1", "20", "23.976", "24", "25", "29.97", "30", "35", "38", "40", "41", "45", "48", "50", "55", "59.94", "60", "65", "70", "72", "75"]},
                    "Apple ProRes 4444": {framerates: [".75", "1", "20", "23.976", "24", "25", "29.97", "30", "35", "38", "40", "41", "45", "48", "50", "55", "59.94", "60", "65", "70", "72", "75"]},
                    "Apple ProRes 422 HQ": {framerates: [".75", "1", "20", "23.976", "24", "25", "29.97", "30", "35", "38", "40", "41", "45", "48", "50", "55", "59.94", "60", "65", "70", "72", "75"]}
                }
            },
            "4K 16:9 (4096 x 2304)": { 
                dimensions: [4096, 2304], 
                codecs: {
                    "ARRIRAW": {framerates: [".75", "1", "20", "23.976", "24", "25", "27", "29.97", "30", "35", "38", "40", "41", "45", "48", "50", "55", "59.94", "60", "65", "70", "72", "75", "80", "90", "95", "96", "100", "105", "120"]},
                    "ARRIRAW  HDE": {framerates: [".75", "1", "20", "23.976", "24", "25", "27", "29.97", "30", "35", "38", "40", "41", "45", "48", "50", "55", "59.94", "60", "65", "70", "72", "75", "80", "90", "95", "96", "100", "105", "120"]},
                    "Apple ProRes 4444 XQ": {framerates: [".75", "1", "20", "23.976", "24", "25", "27", "29.97", "30", "35", "38", "40", "41", "45", "48", "50", "55", "59.94", "60", "65", "70", "72", "75", "80", "90", "95", "96", "100"]},
                    "Apple ProRes 4444": {framerates: [".75", "1", "20", "23.976", "24", "25", "27", "29.97", "30", "35", "38", "40", "41", "45", "48", "50", "55", "59.94", "60", "65", "70", "72", "75", "80", "90", "95", "96", "100"]},
                    "Apple ProRes 422 HQ": {framerates: [".75", "1", "20", "23.976", "24", "25", "27", "29.97", "30", "35", "38", "40", "41", "45", "48", "50", "55", "59.94", "60", "65", "70", "72", "75", "80", "90", "95", "96", "100"]}
                }
            },
            "4K 2:1 (4096 x 2048)": { 
                dimensions: [4096, 2048], 
                codecs: {
                    "ARRIRAW": {framerates: [".75", "1", "20", "23.976", "24", "25", "27", "29.97", "30", "35", "38", "40", "41", "45", "48", "50", "55", "59.94", "60", "65", "70", "72", "75", "80", "90", "95", "96", "100", "105", "120"]},
                    "ARRIRAW  HDE": {framerates: [".75", "1", "20", "23.976", "24", "25", "27", "29.97", "30", "35", "38", "40", "41", "45", "48", "50", "55", "59.94", "60", "65", "70", "72", "75", "80", "90", "95", "96", "100", "105", "120"]},
                    "Apple ProRes 4444 XQ": {framerates: [".75", "1", "20", "23.976", "24", "25", "27", "29.97", "30", "35", "38", "40", "41", "45", "48", "50", "55", "59.94", "60", "65", "70", "72", "75", "80", "90", "95", "96", "100"]},
                    "Apple ProRes 4444": {framerates: [".75", "1", "20", "23.976", "24", "25", "27", "29.97", "30", "35", "38", "40", "41", "45", "48", "50", "55", "59.94", "60", "65", "70", "72", "75", "80", "90", "95", "96", "100"]},
                    "Apple ProRes 422 HQ": {framerates: [".75", "1", "20", "23.976", "24", "25", "27", "29.97", "30", "35", "38", "40", "41", "45", "48", "50", "55", "59.94", "60", "65", "70", "72", "75", "80", "90", "95", "96", "100"]}
                }
            },
            "4K 2.39:1 Ana 2x (4096 x 1716)": { 
                dimensions: [4096, 1716], 
                codecs: {
                    "Apple ProRes 4444 XQ": {framerates: [".75", "1", "20", "23.976", "24", "25", "27", "29.97", "30", "35", "38", "40", "41", "45", "48", "50", "55", "59.94", "60", "65", "70", "72", "75", "80", "90"]},
                    "Apple ProRes 4444": {framerates: [".75", "1", "20", "23.976", "24", "25", "27", "29.97", "30", "35", "38", "40", "41", "45", "48", "50", "55", "59.94", "60", "65", "70", "72", "75", "80", "90"]},
                    "Apple ProRes 422 HQ": {framerates: [".75", "1", "20", "23.976", "24", "25", "27", "29.97", "30", "35", "38", "40", "41", "45", "48", "50", "55", "59.94", "60", "65", "70", "72", "75", "80", "90"]}
                }
            },
            "3.8K 2:1 Ana 2x (3840 x 1920)": { 
                dimensions: [3840, 1920], 
                codecs: {
                    "Apple ProRes 4444 XQ": {framerates: [".75", "1", "20", "23.976", "24", "25", "27", "29.97", "30", "35", "38", "40", "41", "45", "48", "50", "55", "59.94", "60", "65", "70", "72", "75", "80", "90", "95", "96", "100"]},
                    "Apple ProRes 4444": {framerates: [".75", "1", "20", "23.976", "24", "25", "27", "29.97", "30", "35", "38", "40", "41", "45", "48", "50", "55", "59.94", "60", "65", "70", "72", "75", "80", "90", "95", "96", "100"]},
                    "Apple ProRes 422 HQ": {framerates: [".75", "1", "20", "23.976", "24", "25", "27", "29.97", "30", "35", "38", "40", "41", "45", "48", "50", "55", "59.94", "60", "65", "70", "72", "75", "80", "90", "95", "96", "100"]}
                }
            },
            "UHD (3840 x 2160)": { 
                dimensions: [3840, 2160], 
                codecs: {
                    "ARRIRAW": {framerates: [".75", "1", "20", "23.976", "24", "25", "27", "29.97", "30", "35", "38", "40", "41", "45", "48", "50", "55", "59.94", "60", "65", "70", "72", "75", "80", "90", "95", "96", "100", "105", "120"]},
                    "ARRIRAW  HDE": {framerates: [".75", "1", "20", "23.976", "24", "25", "27", "29.97", "30", "35", "38", "40", "41", "45", "48", "50", "55", "59.94", "60", "65", "70", "72", "75", "80", "90", "95", "96", "100", "105", "120"]},
                    "Apple ProRes 4444 XQ": {framerates: [".75", "1", "20", "23.976", "24", "25", "27", "29.97", "30", "35", "38", "40", "41", "45", "48", "50", "55", "59.94", "60", "65", "70", "72", "75", "80", "90", "95", "96", "100", "105", "120"]},
                    "Apple ProRes 4444": {framerates: [".75", "1", "20", "23.976", "24", "25", "27", "29.97", "30", "35", "38", "40", "41", "45", "48", "50", "55", "59.94", "60", "65", "70", "72", "75", "80", "90", "95", "96", "100", "105", "120"]},
                    "Apple ProRes 422 HQ": {framerates: [".75", "1", "20", "23.976", "24", "25", "27", "29.97", "30", "35", "38", "40", "41", "45", "48", "50", "55", "59.94", "60", "65", "70", "72", "75", "80", "90", "95", "96", "100", "105", "120"]}
                }
            },
            "UHD 16:9 Ana 2x (3840 x 2160)": { 
                dimensions: [3840, 2160], 
                codecs: {
                    "Apple ProRes 4444 XQ": {framerates: [".75", "1", "20", "23.976", "24", "25", "27", "29.97", "30", "35", "38", "40", "41", "45", "48", "50", "55", "59.94", "60", "65", "70", "72", "75", "80", "90", "95", "96", "100"]},
                    "Apple ProRes 4444": {framerates: [".75", "1", "20", "23.976", "24", "25", "27", "29.97", "30", "35", "38", "40", "41", "45", "48", "50", "55", "59.94", "60", "65", "70", "72", "75", "80", "90", "95", "96", "100"]},
                    "Apple ProRes 422 HQ": {framerates: [".75", "1", "20", "23.976", "24", "25", "27", "29.97", "30", "35", "38", "40", "41", "45", "48", "50", "55", "59.94", "60", "65", "70", "72", "75", "80", "90", "95", "96", "100"]}
                }
            },            
            "3.3K 6:5 (3328 x 2790)": { 
                dimensions: [3328, 2790], 
                codecs: {
                    "ARRIRAW": {framerates: [".75", "1", "20", "23.976", "24", "25", "27", "29.97", "30", "35", "38", "40", "41", "45", "48", "50", "55", "59.94", "60", "65", "70", "72", "75", "80", "90", "95", "96", "100"]},
                    "ARRIRAW  HDE": {framerates: [".75", "1", "20", "23.976", "24", "25", "27", "29.97", "30", "35", "38", "40", "41", "45", "48", "50", "55", "59.94", "60", "65", "70", "72", "75", "80", "90", "95", "96", "100"]},
                    "Apple ProRes 4444 XQ": {framerates: [".75", "1", "20", "23.976", "24", "25", "27", "29.97", "30", "35", "38", "40", "41", "45", "48", "50", "55", "59.94", "60", "65", "70", "72", "75", "80", "90", "95", "96", "100"]},
                    "Apple ProRes 4444": {framerates: [".75", "1", "20", "23.976", "24", "25", "27", "29.97", "30", "35", "38", "40", "41", "45", "48", "50", "55", "59.94", "60", "65", "70", "72", "75", "80", "90", "95", "96", "100"]},
                    "Apple ProRes 422 HQ": {framerates: [".75", "1", "20", "23.976", "24", "25", "27", "29.97", "30", "35", "38", "40", "41", "45", "48", "50", "55", "59.94", "60", "65", "70", "72", "75", "80", "90", "95", "96", "100"]}
                }
            },
            "3K 1:1 (3072 x 3072)": { 
                dimensions: [3072, 3072], 
                codecs: {
                    "ARRIRAW": {framerates: [".75", "1", "20", "23.976", "24", "25", "27", "29.97", "30", "35", "38", "40", "41", "45", "48", "50", "55", "59.94", "60", "65", "70", "72", "75", "80", "90", "95", "96", "100"]},
                    "ARRIRAW  HDE": {framerates: [".75", "1", "20", "23.976", "24", "25", "27", "29.97", "30", "35", "38", "40", "41", "45", "48", "50", "55", "59.94", "60", "65", "70", "72", "75", "80", "90", "95", "96", "100"]},
                    "Apple ProRes 4444 XQ": {framerates: [".75", "1", "20", "23.976", "24", "25", "27", "29.97", "30", "35", "38", "40", "41", "45", "48", "50", "55", "59.94", "60", "65", "70", "72", "75", "80", "90", "95", "96", "100"]},
                    "Apple ProRes 4444": {framerates: [".75", "1", "20", "23.976", "24", "25", "27", "29.97", "30", "35", "38", "40", "41", "45", "48", "50", "55", "59.94", "60", "65", "70", "72", "75", "80", "90", "95", "96", "100"]},
                    "Apple ProRes 422 HQ": {framerates: [".75", "1", "20", "23.976", "24", "25", "27", "29.97", "30", "35", "38", "40", "41", "45", "48", "50", "55", "59.94", "60", "65", "70", "72", "75", "80", "90", "95", "96", "100"]}
                }
            },
            "2K (2048 x 1152)": { 
                dimensions: [2048, 1152], 
                codecs: {
                    "Apple ProRes 4444 XQ": {framerates: [".75", "1", "20", "23.976", "24", "25", "27", "29.97", "30", "35", "38", "40", "41", "45", "48", "50", "55", "59.94", "60", "65", "70", "72", "75", "80", "90", "95", "96", "100", "105", "120"]},
                    "Apple ProRes 4444": {framerates: [".75", "1", "20", "23.976", "24", "25", "27", "29.97", "30", "35", "38", "40", "41", "45", "48", "50", "55", "59.94", "60", "65", "70", "72", "75", "80", "90", "95", "96", "100", "105", "120"]},
                    "Apple ProRes 422 HQ": {framerates: [".75", "1", "20", "23.976", "24", "25", "27", "29.97", "30", "35", "38", "40", "41", "45", "48", "50", "55", "59.94", "60", "65", "70", "72", "75", "80", "90", "95", "96", "100", "105", "120"]}
                }
            },
            "HD (1920 x 1080)": { 
                dimensions: [1920, 1080], 
                codecs: {
                    "Apple ProRes 4444 XQ": {framerates: [".75", "1", "20", "23.976", "24", "25", "27", "29.97", "30", "35", "38", "40", "41", "45", "48", "50", "55", "59.94", "60", "65", "70", "72", "75", "80", "90", "95", "96", "100", "105", "120"]},
                    "Apple ProRes 4444": {framerates: [".75", "1", "20", "23.976", "24", "25", "27", "29.97", "30", "35", "38", "40", "41", "45", "48", "50", "55", "59.94", "60", "65", "70", "72", "75", "80", "90", "95", "96", "100", "105", "120"]},
                    "Apple ProRes 422 HQ": {framerates: [".75", "1", "20", "23.976", "24", "25", "27", "29.97", "30", "35", "38", "40", "41", "45", "48", "50", "55", "59.94", "60", "65", "70", "72", "75", "80", "90", "95", "96", "100", "105", "120"]}
                }
            },
        }
    },
    "ALEXA Mini": {
        brand: "ARRI",
        resolutions: {
            "16:9 4K UHD (3840 x 2160)": { 
                dimensions: [3840, 2160], 
                codecs: {
                    "Apple ProRes 4444 XQ": {framerates: [".75", "1", "20", "23.976", "24", "25", "29.97", "30"]},
                    "Apple ProRes 4444": {framerates: [".75", "1", "20", "23.976", "24", "25", "29.97", "30"]},
                    "Apple ProRes 422 HQ": {framerates: [".75", "1", "20", "23.976", "24", "25", "29.97", "30"]},
                    "Apple ProRes 422": {framerates: [".75", "1", "20", "23.976", "24", "25", "29.97", "30"]},
                    "Apple ProRes LT": {framerates: [".75", "1", "20", "23.976", "24", "25", "29.97", "30"]}
                }
            },
            "16:9 3.2K UHD (3200 x 1800)": { 
                dimensions: [3200, 1800], 
                codecs: {
                    "Apple ProRes 4444 XQ": {framerates: [".75", "1", "20", "23.976", "24", "25", "29.97", "30"]},
                    "Apple ProRes 4444": {framerates: [".75", "1", "20", "23.976", "24", "25", "29.97", "30"]},
                    "Apple ProRes 422 HQ": {framerates: [".75", "1", "20", "23.976", "24", "25", "29.97", "30"]},
                    "Apple ProRes 422": {framerates: [".75", "1", "20", "23.976", "24", "25", "29.97", "30"]},
                    "Apple ProRes LT": {framerates: [".75", "1", "20", "23.976", "24", "25", "29.97", "30"]}
                }
            },
            "16:9 2.8K  (2880 x 1620)": { 
                dimensions: [2880, 1620], 
                codecs: {
                    "ARRIRAW": {framerates: [".75", "1", "20", "23.976", "24", "25", "27", "29.97", "30", "35", "38", "40", "41", "45", "48"]},
                    "ARRIRAW HDE": {framerates: [".75", "1", "20", "23.976", "24", "25", "27", "29.97", "30", "35", "38", "40", "41", "45", "48"]},
                }
            },
            "Open Gate 3.4K (3424 x 2202)": { 
                dimensions: [3424, 2202], 
                codecs: {
                    "ARRIRAW": {framerates: [".75", "1", "20", "23.976", "24", "25", "27", "29.97", "30"]},
                    "ARRIRAW HDE": {framerates: [".75", "1", "20", "23.976", "24", "25", "27", "29.97", "30"]},
                }
            },
            "4:3 2.8K (OG 3.4K) (3424 x 2202)": { 
                dimensions: [3424, 2202], 
                codecs: {
                    "ARRIRAW": {framerates: [".75", "1", "20", "23.976", "24", "25", "27", "29.97", "30"]},
                    "ARRIRAW HDE": {framerates: [".75", "1", "20", "23.976", "24", "25", "27", "29.97", "30"]},
                }
            },
            "4:3 2.8K (2944 x 2160)": { 
                dimensions: [2944, 2160], 
                codecs: {
                    "Apple ProRes 4444 XQ": {framerates: [".75", "1", "20", "23.976", "24", "25", "29.97", "30", "35", "38", "40", "41", "45", "48", "50"]},
                    "Apple ProRes 4444": {framerates: [".75", "1", "20", "23.976", "24", "25", "29.97", "30", "35", "38", "40", "41", "45", "48", "50"]},
                    "Apple ProRes 422 HQ": {framerates: [".75", "1", "20", "23.976", "24", "25", "29.97", "30", "35", "38", "40", "41", "45", "48", "50"]},
                    "Apple ProRes 422": {framerates: [".75", "1", "20", "23.976", "24", "25", "29.97", "30", "35", "38", "40", "41", "45", "48", "50"]},
                    "Apple ProRes LT": {framerates: [".75", "1", "20", "23.976", "24", "25", "29.97", "30", "35", "38", "40", "41", "45", "48", "50"]}
                }
            },
            "2.39:1 2K Ana. (OG 3.4K) (3424 x 2202)": { 
                dimensions: [3424, 2202], 
                codecs: {
                    "ARRIRAW": {framerates: [".75", "1", "20", "23.976", "24", "25", "27", "29.97", "30"]},
                    "ARRIRAW HDE": {framerates: [".75", "1", "20", "23.976", "24", "25", "27", "29.97", "30"]},
                }
            },
            "16:9 HD Ana.(OG 3.4K) (3424 x 2202)": { 
                dimensions: [3424, 2202], 
                codecs: {
                    "ARRIRAW": {framerates: [".75", "1", "20", "23.976", "24", "25", "27", "29.97", "30"]},
                    "ARRIRAW HDE": {framerates: [".75", "1", "20", "23.976", "24", "25", "27", "29.97", "30"]},
                }
            },
            "16:9 2K (2048 x 1152)": { 
                dimensions: [2048, 1152], 
                codecs: {
                    "Apple ProRes 4444 XQ": {framerates: [".75", "1", "20", "23.976", "24", "25", "27", "29.97", "30", "35", "38", "40", "41", "45", "48", "50", "55", "59.94", "60", "65", "70", "72", "75", "80", "90", "95", "96", "100", "105", "120"]},
                    "Apple ProRes 4444": {framerates: [".75", "1", "20", "23.976", "24", "25", "27", "29.97", "30", "35", "38", "40", "41", "45", "48", "50", "55", "59.94", "60", "65", "70", "72", "75", "80", "90", "95", "96", "100", "105", "120"]},
                    "Apple ProRes 422 HQ": {framerates: [".75", "1", "20", "23.976", "24", "25", "27", "29.97", "30", "35", "38", "40", "41", "45", "48", "50", "55", "59.94", "60", "65", "70", "72", "75", "80", "90", "95", "96", "100", "105", "120"]},
                    "Apple ProRes 422": {framerates: [".75", "1", "20", "23.976", "24", "25", "27", "29.97", "30", "35", "38", "40", "41", "45", "48", "50", "55", "59.94", "60", "65", "70", "72", "75", "80", "90", "95", "96", "100", "105", "120"]},
                    "Apple ProRes LT": {framerates: [".75", "1", "20", "23.976", "24", "25", "27", "29.97", "30", "35", "38", "40", "41", "45", "48", "50", "55", "59.94", "60", "65", "70", "72", "75", "80", "90", "95", "96", "100", "105", "120"]}
                }
            },            
            "2:39:1 2K Ana. (2048 x 858)": { 
                dimensions: [2048, 858], 
                codecs: {
                    "Apple ProRes 4444 XQ": {framerates: [".75", "1", "20", "23.976", "24", "25", "27", "29.97", "30", "35", "38", "40", "41", "45", "48", "50", "55", "59.94", "60", "65", "70", "72", "75", "80", "90", "95", "96", "100", "105", "120"]},
                    "Apple ProRes 4444": {framerates: [".75", "1", "20", "23.976", "24", "25", "27", "29.97", "30", "35", "38", "40", "41", "45", "48", "50", "55", "59.94", "60", "65", "70", "72", "75", "80", "90", "95", "96", "100", "105", "120"]},
                    "Apple ProRes 422 HQ": {framerates: [".75", "1", "20", "23.976", "24", "25", "27", "29.97", "30", "35", "38", "40", "41", "45", "48", "50", "55", "59.94", "60", "65", "70", "72", "75", "80", "90", "95", "96", "100", "105", "120"]},
                    "Apple ProRes 422": {framerates: [".75", "1", "20", "23.976", "24", "25", "27", "29.97", "30", "35", "38", "40", "41", "45", "48", "50", "55", "59.94", "60", "65", "70", "72", "75", "80", "90", "95", "96", "100", "105", "120"]},
                    "Apple ProRes LT": {framerates: [".75", "1", "20", "23.976", "24", "25", "27", "29.97", "30", "35", "38", "40", "41", "45", "48", "50", "55", "59.94", "60", "65", "70", "72", "75", "80", "90", "95", "96", "100", "105", "120"]}
                }
            },            
            "16:9 HD Ana. (1920 x 1080)": { 
                dimensions: [1920, 1080], 
                codecs: {
                    "Apple ProRes 4444 XQ": {framerates: [".75", "1", "20", "23.976", "24", "25", "27", "29.97", "30", "35", "38", "40", "41", "45", "48", "50", "55", "59.94", "60", "65", "70", "72", "75", "80", "90", "95", "96", "100", "105", "120"]},
                    "Apple ProRes 4444": {framerates: [".75", "1", "20", "23.976", "24", "25", "27", "29.97", "30", "35", "38", "40", "41", "45", "48", "50", "55", "59.94", "60", "65", "70", "72", "75", "80", "90", "95", "96", "100", "105", "120"]},
                    "Apple ProRes 422 HQ": {framerates: [".75", "1", "20", "23.976", "24", "25", "27", "29.97", "30", "35", "38", "40", "41", "45", "48", "50", "55", "59.94", "60", "65", "70", "72", "75", "80", "90", "95", "96", "100", "105", "120"]},
                    "Apple ProRes 422": {framerates: [".75", "1", "20", "23.976", "24", "25", "27", "29.97", "30", "35", "38", "40", "41", "45", "48", "50", "55", "59.94", "60", "65", "70", "72", "75", "80", "90", "95", "96", "100", "105", "120"]},
                    "Apple ProRes LT": {framerates: [".75", "1", "20", "23.976", "24", "25", "27", "29.97", "30", "35", "38", "40", "41", "45", "48", "50", "55", "59.94", "60", "65", "70", "72", "75", "80", "90", "95", "96", "100", "105", "120"]}
                }
            },            
            "16:9 HD (1920 x 1080)": { 
                dimensions: [1920, 1080], 
                codecs: {
                    "Apple ProRes 4444 XQ": {framerates: [".75", "1", "20", "23.976", "24", "25", "27", "29.97", "30", "35", "38", "40", "41", "45", "48", "50", "55", "59.94", "60", "65", "70", "72", "75", "80", "90", "95", "96", "100", "105", "120"]},
                    "Apple ProRes 4444": {framerates: [".75", "1", "20", "23.976", "24", "25", "27", "29.97", "30", "35", "38", "40", "41", "45", "48", "50", "55", "59.94", "60", "65", "70", "72", "75", "80", "90", "95", "96", "100", "105", "120"]},
                    "Apple ProRes 422 HQ": {framerates: [".75", "1", "20", "23.976", "24", "25", "27", "29.97", "30", "35", "38", "40", "41", "45", "48", "50", "55", "59.94", "60", "65", "70", "72", "75", "80", "90", "95", "96", "100", "105", "120"]},
                    "Apple ProRes 422": {framerates: [".75", "1", "20", "23.976", "24", "25", "27", "29.97", "30", "35", "38", "40", "41", "45", "48", "50", "55", "59.94", "60", "65", "70", "72", "75", "80", "90", "95", "96", "100", "105", "120"]},
                    "Apple ProRes LT": {framerates: [".75", "1", "20", "23.976", "24", "25", "27", "29.97", "30", "35", "38", "40", "41", "45", "48", "50", "55", "59.94", "60", "65", "70", "72", "75", "80", "90", "95", "96", "100", "105", "120"]}
                }
            },            
            "16:9 S16 HD (1920 x 1080)": { 
                dimensions: [1920, 1080], 
                codecs: {
                    "Apple ProRes 4444 XQ": {framerates: [".75", "1", "20", "23.976", "24", "25", "27", "29.97", "30", "35", "38", "40", "41", "45", "48", "50", "55", "59.94", "60", "65", "70", "72", "75", "80", "90", "95", "96", "100", "105", "120"]},
                    "Apple ProRes 4444": {framerates: [".75", "1", "20", "23.976", "24", "25", "27", "29.97", "30", "35", "38", "40", "41", "45", "48", "50", "55", "59.94", "60", "65", "70", "72", "75", "80", "90", "95", "96", "100", "105", "120"]},
                    "Apple ProRes 422 HQ": {framerates: [".75", "1", "20", "23.976", "24", "25", "27", "29.97", "30", "35", "38", "40", "41", "45", "48", "50", "55", "59.94", "60", "65", "70", "72", "75", "80", "90", "95", "96", "100", "105", "120"]},
                    "Apple ProRes 422": {framerates: [".75", "1", "20", "23.976", "24", "25", "27", "29.97", "30", "35", "38", "40", "41", "45", "48", "50", "55", "59.94", "60", "65", "70", "72", "75", "80", "90", "95", "96", "100", "105", "120"]},
                    "Apple ProRes LT": {framerates: [".75", "1", "20", "23.976", "24", "25", "27", "29.97", "30", "35", "38", "40", "41", "45", "48", "50", "55", "59.94", "60", "65", "70", "72", "75", "80", "90", "95", "96", "100", "105", "120"]}
                }
            },            

        }
    },
    "ALEXA Mini LF": {
        brand: "ARRI",
        resolutions: {
            "4.5K LF 3:2 Open Gate (4480 x 3096)": { 
                dimensions: [4480, 3096], 
                codecs: {
                    "Apple ProRes 4444 XQ": {framerates: [".75", "1", "20", "23.976", "24", "25", "29.97", "30", "35", "38", "40"]},
                    "Apple ProRes 4444": {framerates: [".75", "1", "20", "23.976", "24", "25", "29.97", "30", "35", "38", "40"]},
                    "Apple ProRes 422 HQ": {framerates: [".75", "1", "20", "23.976", "24", "25", "29.97", "30", "35", "38", "40"]}

                }
            },
            "4.5K LF 3:2 Open Gate (4448 x 3096)": { 
                dimensions: [4448, 3096], 
                codecs: {
                    "ARRIRAW": {framerates: [".75", "1", "20", "23.976", "24", "25", "27", "29.97", "30", "35", "38", "40"]},
                    "ARRIRAW HDE": {framerates: [".75", "1", "20", "23.976", "24", "25", "27", "29.97", "30", "35", "38", "40"]},

                }
            },
            "4.5K LF 2.39:1 (4480 x 1856)": { 
                dimensions: [4480, 1856], 
                codecs: {
                    "Apple ProRes 4444 XQ": {framerates: [".75", "1", "20", "23.976", "24", "25", "29.97", "30", "35", "38", "40", "41", "45", "48", "50", "55", "59.94", "60"]},
                    "Apple ProRes 4444": {framerates: [".75", "1", "20", "23.976", "24", "25", "29.97", "30", "35", "38", "40", "41", "45", "48", "50", "55", "59.94", "60"]},
                    "Apple ProRes 422 HQ": {framerates: [".75", "1", "20", "23.976", "24", "25", "29.97", "30", "35", "38", "40", "41", "45", "48", "50", "55", "59.94", "60"]}
                }
            },
            "4.5K LF 2.39:1 (4448 x 1856)": { 
                dimensions: [4448, 1856], 
                codecs: {
                    "ARRIRAW": {framerates: [".75", "1", "20", "23.976", "24", "25", "27", "29.97", "30", "35", "38", "40", "41", "45", "48", "50", "55", "59.94", "60"]},
                    "ARRIRAW HDE": {framerates: [".75", "1", "20", "23.976", "24", "25", "27", "29.97", "30", "35", "38", "40", "41", "45", "48", "50", "55", "59.94", "60"]}
                }
            },
            "4.3K LF 16:9 - UHD (3840 x 2160)": { 
                dimensions: [3840, 2160], 
                codecs: {
                    "Apple ProRes 4444 XQ": {framerates: [".75", "1", "20", "23.976", "24", "25", "29.97", "30", "35", "38", "40", "41", "45", "48"]},
                    "Apple ProRes 4444": {framerates: [".75", "1", "20", "23.976", "24", "25", "29.97", "30", "35", "38", "40", "41", "45", "48"]},
                    "Apple ProRes 422 HQ": {framerates: [".75", "1", "20", "23.976", "24", "25", "29.97", "30", "35", "38", "40", "41", "45", "48"]}                    
                }
            },
            "3.8K LF 16:9 - UHD (3840 x 2160)": { 
                dimensions: [3840, 2160], 
                codecs: {
                    "ARRIRAW": {framerates: [".75", "1", "20", "23.976", "24", "25", "27", "29.97", "30", "35", "38", "40", "41", "45", "48", "50", "55", "59.94", "60"]},
                    "ARRIRAW HDE": {framerates: [".75", "1", "20", "23.976", "24", "25", "27", "29.97", "30", "35", "38", "40", "41", "45", "48", "50", "55", "59.94", "60"]},
                    "Apple ProRes 4444 XQ": {framerates: [".75", "1", "20", "23.976", "24", "25", "29.97", "30", "35", "38", "40", "41", "45", "48", "50", "55", "59.94", "60"]},
                    "Apple ProRes 4444": {framerates: [".75", "1", "20", "23.976", "24", "25", "29.97", "30", "35", "38", "40", "41", "45", "48", "50", "55", "59.94", "60"]},
                    "Apple ProRes 422 HQ": {framerates: [".75", "1", "20", "23.976", "24", "25", "29.97", "30", "35", "38", "40", "41", "45", "48", "50", "55", "59.94", "60"]}
                }
            },
            "3.8K LF 16:9 - 2K (2048 x 1152)": { 
                dimensions: [2048, 1152], 
                codecs: {
                    "Apple ProRes 4444 XQ": {framerates: [".75", "1", "20", "23.976", "24", "25", "27", "29.97", "30", "35", "38", "40", "41", "45", "48", "50", "55", "59.94", "60", "65", "70", "72", "75", "80", "90"]},
                    "Apple ProRes 4444": {framerates: [".75", "1", "20", "23.976", "24", "25", "27", "29.97", "30", "35", "38", "40", "41", "45", "48", "50", "55", "59.94", "60", "65", "70", "72", "75", "80", "90"]},
                    "Apple ProRes 422 HQ": {framerates: [".75", "1", "20", "23.976", "24", "25", "27", "29.97", "30", "35", "38", "40", "41", "45", "48", "50", "55", "59.94", "60", "65", "70", "72", "75", "80", "90"]}
                }
            },
            "3.4K S35 3:2 (3424 x 2202)": { 
                dimensions: [3424, 2202], 
                codecs: {
                    "ARRIRAW": {framerates: [".75", "1", "20", "23.976", "24", "25", "27", "29.97", "30", "35", "38", "40", "41", "45", "48", "50", "55", "59.94", "60"]},
                    "ARRIRAW HDE": {framerates: [".75", "1", "20", "23.976", "24", "25", "27", "29.97", "30", "35", "38", "40", "41", "45", "48", "50", "55", "59.94", "60"]},
                }
            },
            "2.8K LF 1:1 (2880 x 2880)": { 
                dimensions: [2880, 2880], 
                codecs: {
                    "ARRIRAW": {framerates: [".75", "1", "20", "23.976", "24", "25", "27", "29.97", "30", "35", "38", "40", "41", "45", "48", "50", "55", "59.94", "60"]},
                    "ARRIRAW HDE": {framerates: [".75", "1", "20", "23.976", "24", "25", "27", "29.97", "30", "35", "38", "40", "41", "45", "48", "50", "55", "59.94", "60"]},
                    
                }
            },
            "4.3K LF 16:9 - HD (1920 x 1080)": { 
                dimensions: [1920, 1080], 
                codecs: {
                    "Apple ProRes 4444 XQ": {framerates: [".75", "1", "20", "23.976", "24", "25", "27", "29.97", "30", "35", "38", "40", "41", "45", "48", "50", "55", "59.94", "60", "65", "70", "72", "75"]},
                    "Apple ProRes 4444": {framerates: [".75", "1", "20", "23.976", "24", "25", "27", "29.97", "30", "35", "38", "40", "41", "45", "48", "50", "55", "59.94", "60", "65", "70", "72", "75"]},
                    "Apple ProRes 422 HQ": {framerates: [".75", "1", "20", "23.976", "24", "25", "27", "29.97", "30", "35", "38", "40", "41", "45", "48", "50", "55", "59.94", "60", "65", "70", "72", "75"]}                    
                }
            },
            "3.8K LF 16:9 - HD (1920 x 1080)": { 
                dimensions: [1920, 1080], 
                codecs: {
                    "Apple ProRes 4444 XQ": {framerates: [".75", "1", "20", "23.976", "24", "25", "27", "29.97", "30", "35", "38", "40", "41", "45", "48", "50", "55", "59.94", "60", "65", "70", "72", "75", "80", "90"]},
                    "Apple ProRes 4444": {framerates: [".75", "1", "20", "23.976", "24", "25", "27", "29.97", "30", "35", "38", "40", "41", "45", "48", "50", "55", "59.94", "60", "65", "70", "72", "75", "80", "90"]},
                    "Apple ProRes 422 HQ": {framerates: [".75", "1", "20", "23.976", "24", "25", "27", "29.97", "30", "35", "38", "40", "41", "45", "48", "50", "55", "59.94", "60", "65", "70", "72", "75", "80", "90"]}
                }
            },            
        }
    },
    "ALEXA LF": {
        brand: "ARRI",
        resolutions: {
            "4.5K LF 3:2 Open Gate (4448 x 3096)": { 
                dimensions: [4448, 3096], 
                codecs: {
                    "ARRIRAW": {framerates: [".75", "1", "20", "23.976", "24", "25", "27", "29.97", "30", "35", "38", "40", "41", "45", "48", "50", "55", "59.94", "60", "65", "70", "72", "75", "80", "90"]},
                    "ARRIRAW HDE": {framerates: [".75", "1", "20", "23.976", "24", "25", "27", "29.97", "30", "35", "38", "40", "41", "45", "48", "50", "55", "59.94", "60", "65", "70", "72", "75", "80", "90"]},
                    "Apple ProRes 4444 XQ": {framerates: [".75", "1", "20", "23.976", "24", "25", "29.97", "30", "35", "38", "40", "41", "45", "48", "50", "55", "59.94", "60"]},
                    "Apple ProRes 4444": {framerates: [".75", "1", "20", "23.976", "24", "25", "29.97", "30", "35", "38", "40", "41", "45", "48", "50", "55", "59.94", "60"]},
                    "Apple ProRes 422 HQ": {framerates: [".75", "1", "20", "23.976", "24", "25", "29.97", "30", "35", "38", "40", "41", "45", "48", "50", "55", "59.94", "60"]},
                    "Apple ProRes 422": {framerates: [".75", "1", "20", "23.976", "24", "25", "29.97", "30", "35", "38", "40", "41", "45", "48", "50", "55", "59.94", "60"]}

                }
            },
            "4.5K LF 2.39:1 (4480 x 1856)": { 
                dimensions: [4480, 1856], 
                codecs: {
                    "ARRIRAW": {framerates: [".75", "1", "20", "23.976", "24", "25", "27", "29.97", "30", "35", "38", "40", "41", "45", "48", "50", "55", "59.94", "60", "65", "70", "72", "75", "80", "90", "95", "96", "100", "105", "120", "150"]},
                    "ARRIRAW HDE": {framerates: [".75", "1", "20", "23.976", "24", "25", "27", "29.97", "30", "35", "38", "40", "41", "45", "48", "50", "55", "59.94", "60", "65", "70", "72", "75", "80", "90", "95", "96", "100", "105", "120", "150"]},
                    "Apple ProRes 4444 XQ": {framerates: [".75", "1", "20", "23.976", "24", "25", "27", "29.97", "30", "35", "38", "40", "41", "45", "48", "50", "55", "59.94", "60", "65", "70", "72", "75", "80", "90", "95", "96", "100"]},
                    "Apple ProRes 4444": {framerates: [".75", "1", "20", "23.976", "24", "25", "27", "29.97", "30", "35", "38", "40", "41", "45", "48", "50", "55", "59.94", "60", "65", "70", "72", "75", "80", "90", "95", "96", "100"]},
                    "Apple ProRes 422 HQ": {framerates: [".75", "1", "20", "23.976", "24", "25", "27", "29.97", "30", "35", "38", "40", "41", "45", "48", "50", "55", "59.94", "60", "65", "70", "72", "75", "80", "90", "95", "96", "100"]},
                    "Apple ProRes 422": {framerates: [".75", "1", "20", "23.976", "24", "25", "27", "29.97", "30", "35", "38", "40", "41", "45", "48", "50", "55", "59.94", "60", "65", "70", "72", "75", "80", "90", "95", "96", "100"]}
                }
            },
            "3.8K LF 16:9 - UHD (3840 x 2160)": { 
                dimensions: [3840, 2160], 
                codecs: {
                    "ARRIRAW": {framerates: [".75", "1", "20", "23.976", "24", "25", "27", "29.97", "30", "35", "38", "40", "41", "45", "48", "50", "55", "59.94", "60", "65", "70", "72", "75", "80", "90"]},
                    "ARRIRAW HDE": {framerates: [".75", "1", "20", "23.976", "24", "25", "27", "29.97", "30", "35", "38", "40", "41", "45", "48", "50", "55", "59.94", "60", "65", "70", "72", "75", "80", "90"]},
                    "Apple ProRes 4444 XQ": {framerates: [".75", "1", "20", "23.976", "24", "25", "29.97", "30", "35", "38", "40", "41", "45", "48", "50", "55", "59.94", "60"]},
                    "Apple ProRes 4444": {framerates: [".75", "1", "20", "23.976", "24", "25", "29.97", "30", "35", "38", "40", "41", "45", "48", "50", "55", "59.94", "60"]},
                    "Apple ProRes 422 HQ": {framerates: [".75", "1", "20", "23.976", "24", "25", "29.97", "30", "35", "38", "40", "41", "45", "48", "50", "55", "59.94", "60"]},
                    "Apple ProRes 422": {framerates: [".75", "1", "20", "23.976", "24", "25", "29.97", "30", "35", "38", "40", "41", "45", "48", "50", "55", "59.94", "60"]}
                }
            },
        }
    },
    "AMIRA": {
        brand: "ARRI",
        resolutions: {
            "16:9 4K UHD (3840 x 2160)": { 
                dimensions: [3840, 2160], 
                codecs: {
                    "Apple ProRes 4444 XQ": {framerates: [".75", "1", "20", "23.976", "24", "25", "29.97", "30"]},
                    "Apple ProRes 4444": {framerates: [".75", "1", "20", "23.976", "24", "25", "27", "29.97", "30", "35", "38", "40", "41", "45", "48", "50", "55", "59.94", "60"]},
                    "Apple ProRes 422 HQ": {framerates: [".75", "1", "20", "23.976", "24", "25", "27", "29.97", "30", "35", "38", "40", "41", "45", "48", "50", "55", "59.94", "60"]},
                    "Apple ProRes 422": {framerates: [".75", "1", "20", "23.976", "24", "25", "27", "29.97", "30", "35", "38", "40", "41", "45", "48", "50", "55", "59.94", "60"]},
                    "Apple ProRes LT": {framerates: [".75", "1", "20", "23.976", "24", "25", "27", "29.97", "30", "35", "38", "40", "41", "45", "48", "50", "55", "59.94", "60"]}
                }
            },
            "16:9 3.2K (3200 x 1800)": { 
                dimensions: [3200, 1800], 
                codecs: {
                    "Apple ProRes 4444 XQ": {framerates: [".75", "1", "20", "23.976", "24", "25", "29.97", "30"]},
                    "Apple ProRes 4444": {framerates: [".75", "1", "20", "23.976", "24", "25", "27", "29.97", "30", "35", "38", "40", "41", "45", "48", "50", "55", "59.94", "60"]},
                    "Apple ProRes 422 HQ": {framerates: [".75", "1", "20", "23.976", "24", "25", "27", "29.97", "30", "35", "38", "40", "41", "45", "48", "50", "55", "59.94", "60"]},
                    "Apple ProRes 422": {framerates: [".75", "1", "20", "23.976", "24", "25", "27", "29.97", "30", "35", "38", "40", "41", "45", "48", "50", "55", "59.94", "60"]},
                    "Apple ProRes LT": {framerates: [".75", "1", "20", "23.976", "24", "25", "27", "29.97", "30", "35", "38", "40", "41", "45", "48", "50", "55", "59.94", "60"]}
                }
            },
            "16:9 2.8K  (2880 x 1620)": { 
                dimensions: [2880, 1620], 
                codecs: {
                    "ARRIRAW": {framerates: [".75", "1", "20", "23.976", "24", "25", "27", "29.97", "30", "35", "38", "40", "41", "45", "48"]},
                    "ARRIRAW HDE": {framerates: [".75", "1", "20", "23.976", "24", "25", "27", "29.97", "30", "35", "38", "40", "41", "45", "48"]}
                }
            },

            "16:9 2K (2048 x 1152)": { 
                dimensions: [2048, 1152], 
                codecs: {
                    "Apple ProRes 4444 XQ": {framerates: [".75", "1", "20", "23.976", "24", "25", "27", "29.97", "30", "35", "38", "40", "41", "45", "48", "50", "55", "59.94", "60", "65", "70", "72", "75", "80", "90", "95", "96", "100", "105", "120"]},
                    "Apple ProRes 4444": {framerates: [".75", "1", "20", "23.976", "24", "25", "27", "29.97", "30", "35", "38", "40", "41", "45", "48", "50", "55", "59.94", "60", "65", "70", "72", "75", "80", "90", "95", "96", "100", "105", "120", "150", "200"]},
                    "Apple ProRes 422 HQ": {framerates: [".75", "1", "20", "23.976", "24", "25", "27", "29.97", "30", "35", "38", "40", "41", "45", "48", "50", "55", "59.94", "60", "65", "70", "72", "75", "80", "90", "95", "96", "100", "105", "120", "150", "200"]},
                    "Apple ProRes 422": {framerates: [".75", "1", "20", "23.976", "24", "25", "27", "29.97", "30", "35", "38", "40", "41", "45", "48", "50", "55", "59.94", "60", "65", "70", "72", "75", "80", "90", "95", "96", "100", "105", "120", "150", "200"]},
                    "Apple ProRes LT": {framerates: [".75", "1", "20", "23.976", "24", "25", "27", "29.97", "30", "35", "38", "40", "41", "45", "48", "50", "55", "59.94", "60", "65", "70", "72", "75", "80", "90", "95", "96", "100", "105", "120", "150", "200"]}
                }
            },            

            "16:9 S16 HD (1920 x 1080)": { 
                dimensions: [1920, 1080], 
                codecs: {
                    "Apple ProRes 4444 XQ": {framerates: [".75", "1", "20", "23.976", "24", "25", "27", "29.97", "30", "35", "38", "40", "41", "45", "48", "50", "55", "59.94", "60", "65", "70", "72", "75", "80", "90", "95", "96", "100", "105", "120"]},
                    "Apple ProRes 4444": {framerates: [".75", "1", "20", "23.976", "24", "25", "27", "29.97", "30", "35", "38", "40", "41", "45", "48", "50", "55", "59.94", "60", "65", "70", "72", "75", "80", "90", "95", "96", "100", "105", "120", "150", "200"]},
                    "Apple ProRes 422 HQ": {framerates: [".75", "1", "20", "23.976", "24", "25", "27", "29.97", "30", "35", "38", "40", "41", "45", "48", "50", "55", "59.94", "60", "65", "70", "72", "75", "80", "90", "95", "96", "100", "105", "120", "150", "200"]},
                    "Apple ProRes 422": {framerates: [".75", "1", "20", "23.976", "24", "25", "27", "29.97", "30", "35", "38", "40", "41", "45", "48", "50", "55", "59.94", "60", "65", "70", "72", "75", "80", "90", "95", "96", "100", "105", "120", "150", "200"]},
                    "Apple ProRes LT": {framerates: [".75", "1", "20", "23.976", "24", "25", "27", "29.97", "30", "35", "38", "40", "41", "45", "48", "50", "55", "59.94", "60", "65", "70", "72", "75", "80", "90", "95", "96", "100", "105", "120", "150", "200"]}
                }
            },            

            "16:9 HD (1920 x 1080)": { 
                dimensions: [1920, 1080], 
                codecs: {
                    "Apple ProRes 4444 XQ": {framerates: [".75", "1", "20", "23.976", "24", "25", "27", "29.97", "30", "35", "38", "40", "41", "45", "48", "50", "55", "59.94", "60", "65", "70", "72", "75", "80", "90", "95", "96", "100", "105", "120"]},
                    "Apple ProRes 4444": {framerates: [".75", "1", "20", "23.976", "24", "25", "27", "29.97", "30", "35", "38", "40", "41", "45", "48", "50", "55", "59.94", "60", "65", "70", "72", "75", "80", "90", "95", "96", "100", "105", "120", "150", "200"]},
                    "Apple ProRes 422 HQ": {framerates: [".75", "1", "20", "23.976", "24", "25", "27", "29.97", "30", "35", "38", "40", "41", "45", "48", "50", "55", "59.94", "60", "65", "70", "72", "75", "80", "90", "95", "96", "100", "105", "120", "150", "200"]},
                    "Apple ProRes 422": {framerates: [".75", "1", "20", "23.976", "24", "25", "27", "29.97", "30", "35", "38", "40", "41", "45", "48", "50", "55", "59.94", "60", "65", "70", "72", "75", "80", "90", "95", "96", "100", "105", "120", "150", "200"]},
                    "Apple ProRes LT": {framerates: [".75", "1", "20", "23.976", "24", "25", "27", "29.97", "30", "35", "38", "40", "41", "45", "48", "50", "55", "59.94", "60", "65", "70", "72", "75", "80", "90", "95", "96", "100", "105", "120", "150", "200"]},
                    "MPEG-2 HD 422": {framerates: [".75", "1", "20", "23.976", "24", "25", "27", "29.97", "30", "35", "38", "40", "41", "45", "48", "50", "55", "59.94"]}
                }
            },            
            
        }
    },
    "ALEXA  65": {
        brand: "ARRI",
        resolutions: {
            "Open Gate (6560 x 3100)": { 
                dimensions: [6560, 3100], 
                codecs: {
                    "ARRIRAW": {framerates: ["20", "23.976", "24", "25", "27", "29.97", "30", "35", "37", "38", "40", "41", "42", "43", "45", "48", "50", "55", "59.94", "60"]},
                    }
                },
            "5.1K 16:9 (5120 x 2880)": { 
                dimensions: [5120, 2880], 
                codecs: {
                    "ARRIRAW": {framerates: ["20", "23.976", "24", "25", "27", "29.97", "30", "35", "37", "38", "40", "41", "42", "43", "45", "48", "50", "55", "59.94", "60"]},
                    }
                },
            "LF Open Gate (4448 x 3096)": { 
                dimensions: [4448, 3096], 
                codecs: {
                    "ARRIRAW": {framerates: ["20", "23.976", "24", "25", "27", "29.97", "30", "35", "37", "38", "40", "41", "42", "43", "45", "48", "50", "55", "59.94", "60"]},
                    }
                },
            "4.3K 3:2 (4320 x 2880)": { 
                dimensions: [4320, 2880], 
                codecs: {
                    "ARRIRAW": {framerates: ["20", "23.976", "24", "25", "27", "29.97", "30", "35", "37", "38", "40", "41", "42", "43", "45", "48", "50", "55", "59.94", "60"]},
                    }
                },
            "UHD (3840 x 2160)": { 
                dimensions: [3840, 2160], 
                codecs: {
                    "ARRIRAW": {framerates: ["20", "23.976", "24", "25", "27", "29.97", "30", "35", "37", "38", "40", "41", "42", "43", "45", "48", "50", "55", "59.94", "60"]},
                    }
                },
            }
        },
        "ALEXA 265": {
            brand: "ARRI",
            resolutions: {
                "6.5K 2.12:1Open Gate (6560 x 3100)": { 
                    dimensions: [6560, 3100], 
                    codecs: {
                        "ARRIRAW": {framerates: [".75", "1", "20", "23.976", "24", "25", "27", "29", "29.97", "30", "35", "37", "38", "40", "41", "42", "43", "45", "48", "50", "55", "59.94", "60"]},
                    }
                },
                "5.1K (5120 x 3100)": { 
                    dimensions: [5120, 3100], 
                    codecs: {
                        "ARRIRAW": {framerates: [".75", "1", "20", "23.976", "24", "25", "27", "29", "29.97", "30", "35", "37", "38", "40", "41", "42", "43", "45", "48", "50", "55", "59.94", "60"]},
                    }
                },
                "4.5K (4448 x 3096)": { 
                    dimensions: [4448, 3096], 
                    codecs: {
                        "ARRIRAW": {framerates: [".75", "1", "20", "23.976", "24", "25", "27", "29", "29.97", "30", "35", "37", "38", "40", "41", "42", "43", "45", "48", "50", "55", "59.94", "60"]},
                    }
                },
            }
        },
    // ... other cameras ...
};