import React, { useState, useEffect, useRef, useCallback } from 'react';
import { SlDocs, SlNote, SlRefresh, SlLike, SlPaperPlane } from "react-icons/sl";
import { FaShareSquare, FaLock, FaUnlockAlt, FaAngleLeft, FaAngleRight } from 'react-icons/fa';
import { getCamerasByBrand, getResolutionsForCamera, getFrameratesForCamera, getCodecsForCameraAndResolution } from '../data/cameraData';
import { codecs } from '../data/codecData';
import { brands } from '../data/brandData';
import { resolutions } from '../data/resolutionData';
import { disableScroll, enableScroll } from '../utils/utils';
import { useCalculator } from '../context/CalculatorContext';
import { useDropdownTouch } from '../hooks/useDropdownTouch';
import { colors, spacing, commonStyles, layout } from '../styles/globalStyles';
import CalculatorButtons from './CalculatorButtons';
import { handleShareOnDevice } from '../utils/shareUtils';

// Timecode class for handling timecode operations
class Timecode {
  constructor(hours = 0, minutes = 0, seconds = 0, frames = 0, frameRate = 24) {
    this.hours = Math.floor(hours);
    this.minutes = Math.floor(minutes);
    this.seconds = Math.floor(seconds);
    this.frames = Math.floor(frames);
    this.frameRate = frameRate;
  }

  // Parse a timecode string into a Timecode object
  static parse(timecodeString, frameRate = 24) {
    const parts = timecodeString.split(/[:.]/).map(Number);
    while (parts.length < 4) parts.unshift(0);
    const [hours, minutes, seconds, frames] = parts;
    return new Timecode(hours, minutes, seconds, frames, frameRate);
  }
  
  // Convert Timecode object to string
  toString() {
    return `${this.hours.toString().padStart(2, '0')}:${this.minutes.toString().padStart(2, '0')}:${this.seconds.toString().padStart(2, '0')}:${this.frames.toString().padStart(2, '0')}`;
  }

  // Calculate total frames
  totalFrames() {
    return this.hours * 3600 * this.frameRate + 
           this.minutes * 60 * this.frameRate + 
           this.seconds * this.frameRate + 
           this.frames;
  }

  // Create a Timecode object from total frames
  static fromFrames(totalFrames, frameRate = 24) {
    const hours = Math.floor(totalFrames / (3600 * frameRate));
    totalFrames %= 3600 * frameRate;
    const minutes = Math.floor(totalFrames / (60 * frameRate));
    totalFrames %= 60 * frameRate;
    const seconds = Math.floor(totalFrames / frameRate);
    const frames = Math.floor(totalFrames % frameRate);

    return new Timecode(hours, minutes, seconds, frames, frameRate);
  }
}

// Add the formatBitrate helper function near the top of the file
const formatBitrate = (bitrateInBytes) => {
  if (bitrateInBytes >= 1024 * 1024 * 1024) {
    return `${(bitrateInBytes / (1024 * 1024 * 1024)).toFixed(2)} GB/s`;
  } else if (bitrateInBytes >= 1024 * 1024) {
    return `${(bitrateInBytes / (1024 * 1024)).toFixed(2)} MB/s`;
  } else if (bitrateInBytes >= 1024) {
    return `${(bitrateInBytes / 1024).toFixed(2)} KB/s`;
  } else {
    return `${bitrateInBytes.toFixed(2)} B/s`;
  }
};

const CameraToFileSize = ({ addLog }) => {
  const { cameraCalcState, setCameraCalcState } = useCalculator();
  
  // State variables - declare ALL state first
  const [calcData, setCalcData] = useState(cameraCalcState);
  const [durationInput, setDurationInput] = useState(cameraCalcState.durationInput);
  const [fileSizeInput, setFileSizeInput] = useState(cameraCalcState.fileSizeInput);
  const [fileSizeUnit, setFileSizeUnit] = useState(cameraCalcState.fileSizeUnit);
  const [isDurationLocked, setIsDurationLocked] = useState(cameraCalcState.isDurationLocked);
  const [isFileSizeLocked, setIsFileSizeLocked] = useState(cameraCalcState.isFileSizeLocked);
  const [calculationMode, setCalculationMode] = useState(cameraCalcState.calculationMode);
  const [availableCameras, setAvailableCameras] = useState(cameraCalcState.availableCameras);
  const [availableResolutions, setAvailableResolutions] = useState(cameraCalcState.availableResolutions);
  const [availableFramerates, setAvailableFramerates] = useState(cameraCalcState.availableFramerates);
  const [availableCodecs, setAvailableCodecs] = useState(cameraCalcState.availableCodecs);
  const [copyStatus, setCopyStatus] = useState(cameraCalcState.copyStatus);
  const [isMobile, setIsMobile] = useState(cameraCalcState.isMobile);
  const [copiedLogIndex, setCopiedLogIndex] = useState(cameraCalcState.copiedLogIndex);
  const [logs, setLogs] = useState([]);
  const [copyDurationText, setCopyDurationText] = useState(<SlDocs />);
  const [copyFileSizeText, setCopyFileSizeText] = useState(<SlDocs />);
  const [logButtonText, setLogButtonText] = useState(<SlNote />);
  const [shareButtonText, setShareButtonText] = useState(<SlPaperPlane />);
  const [resetButtonText, setResetButtonText] = useState(<SlRefresh />);
  const [lockedField, setLockedField] = useState(cameraCalcState.lockedField);
  const [previousResolution, setPreviousResolution] = useState(cameraCalcState.previousResolution);

  // Refs
  const resolutionRef = useRef(null);
  const codecRef = useRef(null);
  const durationRef = useRef(null);
  const framerateRef = useRef(null);
  const fileSizeRef = useRef(null);
  const logRef = useRef(null);
  // const brandRef = useRef(null);
  // const cameraRef = useRef(null);

  // Update context whenever relevant state changes
  useEffect(() => {
    setCameraCalcState({
      ...calcData,
      durationInput,
      fileSizeInput,
      fileSizeUnit,
      isDurationLocked,
      isFileSizeLocked,
      calculationMode,
      availableCameras,
      availableResolutions,
      availableFramerates,
      availableCodecs,
      copyStatus,
      isMobile,
      copiedLogIndex,
      copyDurationText,
      copyFileSizeText,
      logButtonText,
      shareButtonText,
      resetButtonText,
      previousResolution
    });
  }, [
    calcData,
    durationInput,
    fileSizeInput,
    fileSizeUnit,
    isDurationLocked,
    isFileSizeLocked,
    calculationMode,
    availableCameras,
    availableResolutions,
    availableFramerates,
    availableCodecs,
    copyStatus,
    isMobile,
    copiedLogIndex,
    copyDurationText,
    copyFileSizeText,
    logButtonText,
    shareButtonText,
    resetButtonText,
    previousResolution,
    setCameraCalcState
  ]);

  const calculateDuration = (data = calcData, fileSizeInput) => {
    if (!data) {
      console.error('Invalid data for duration calculation');
      return "00:00:00:01"; // Ensure at least 1 frame
    }

    // if (!data || !data.fileSize || !data.fileSizeUnit) {
    //   console.error('Invalid data for duration calculation');
    //   return "00:00:00:01"; // Ensure at least 1 frame
    // }

    const selectedCodec = codecs.find(codec => codec.name === data.codec);
    if (!selectedCodec) {
      console.error('Invalid codec');
      return "00:00:00:01";
    }

    const datarate = selectedCodec.datarate;
    const resolutionMatch = data.resolution.match(/\((\d+)\s*x\s*(\d+)\)/);
    let resolutionValue;
    if (resolutionMatch) {
      const [, width, height] = resolutionMatch;
      resolutionValue = parseInt(width) * parseInt(height);
    } else {
      console.error('Invalid resolution format');
      return "00:00:00:01";
    }

    const fps = parseFloat(data.framerate);
    const parsedSize = parseFileSize(fileSizeInput || `${data.fileSize} ${fileSizeUnit}`);
    if (!parsedSize) {
      console.error('Invalid file size');
      return "00:00:00:01"; // Ensure at least 1 frame
    }

    // Unit multipliers for file size calculations
    const unitMultiplier = {
      'B': 1,
      'KB': 1024,
      'MB': 1024 * 1024,
      'GB': 1024 * 1024 * 1024,
      'TB': 1024 * 1024 * 1024 * 1024,
      'PB': 1024 * 1024 * 1024 * 1024 * 1024
    };

    const size_in_bytes = parseFloat(parsedSize.size) * unitMultiplier[parsedSize.unit];

    const bytes_per_second = datarate * resolutionValue * fps;

    let total_seconds = size_in_bytes / (bytes_per_second * 1024 * 1024 / 8);

    console.log("Calculated total seconds:", total_seconds); // Debug log

    // Ensure the total_seconds is at least enough for one frame
    if (total_seconds < 1 / fps) {
      total_seconds = 1 / fps;
    }

    const hours = Math.floor(total_seconds / 3600);
    total_seconds %= 3600;
    const minutes = Math.floor(total_seconds / 60);
    total_seconds %= 60;
    const seconds = Math.floor(total_seconds);
    const frames = Math.round((total_seconds - seconds) * fps);

    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}:${frames.toString().padStart(2, '0')}`;
  };

  // Convert resolutions object to array for select options
  const resolutionOptions = Object.keys(resolutions);

  // Get resolution string for display
  let resolutionString;
  if (calcData.resolution === 'Custom') {
    resolutionString = calcData.customResolution;
  } else {
    const match = calcData.resolution.match(/\((\d+\s*x\s*\d+)\)/);
    resolutionString = match ? match[1].replace(/\s+/g, '') : calcData.resolution;
  }

  // Ref for previous calcData
  const prevCalcData = useRef(calcData);

  // Effect to check if the device is mobile
  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));
    };
    checkMobile();
    window.addEventListener('resize', checkMobile);

    return () => window.removeEventListener('resize', checkMobile);
  }, []);


  // Update the handleDurationLockClick function
  const handleDurationLockClick = () => {
    if (isDurationLocked) {
      // Unlock duration
      setIsDurationLocked(false);
      setCalculationMode(null);
    } else {
      // Lock duration and unlock file size
      setIsDurationLocked(true);
      setIsFileSizeLocked(false);
      setCalculationMode('duration');
      setCalcData(prevData => ({
        ...prevData,
        duration: durationInput,
        fileSize: parseFloat(fileSizeInput.split(' ')[0]) // Ensure fileSize is correctly set
      }));
    }
  };

  // Handler for file size lock click
  const handleFileSizeLockClick = () => {
    if (isFileSizeLocked) {
      // Unlock file size
      setIsFileSizeLocked(false);
      setCalculationMode(null);
    } else {
      // Lock file size and unlock duration
      setIsFileSizeLocked(true);
      setIsDurationLocked(false);
      setCalculationMode('fileSize');
      setCalcData(prevData => ({
        ...prevData,
        fileSize: parseFloat(fileSizeInput.split(' ')[0]),
        duration: durationInput
      }));
    }
  };

  useEffect(() => {
    const cameras = getCamerasByBrand(calcData.brand);
    setAvailableCameras(cameras);
    
    if (!cameras.includes(calcData.camera)) {
      const newCamera = cameras[0] || '';
      setCalcData(prev => ({ 
        ...prev, 
        camera: newCamera,
        resolution: '', // Reset resolution
        codec: '',      // Reset codec
        framerate: ''   // Reset framerate
      }));
    }
  }, [calcData.brand]);

  useEffect(() => {
    if (calcData.camera) {
      try {
        const resolutions = getResolutionsForCamera(calcData.camera);
        setAvailableResolutions(resolutions);
        
        if (!resolutions.includes(calcData.resolution)) {
          const newResolution = resolutions[0] || '';
          setCalcData(prev => ({ 
            ...prev, 
            resolution: newResolution,
            codec: '',      // Reset codec
            framerate: ''   // Reset framerate
          }));
        }
      } catch (error) {
        console.error('Error getting resolutions:', error);
        setAvailableResolutions([]);
      }
    }
  }, [calcData.camera]);

  useEffect(() => {
    if (calcData.camera && calcData.resolution) {
      try {
        const codecs = getCodecsForCameraAndResolution(calcData.camera, calcData.resolution);
        setAvailableCodecs(codecs);
        
        if (!codecs.includes(calcData.codec)) {
          const newCodec = codecs[0] || '';
          setCalcData(prev => ({ 
            ...prev, 
            codec: newCodec,
            framerate: '' // Reset framerate
          }));
        }
      } catch (error) {
        console.error('Error getting codecs:', error);
        setAvailableCodecs([]);
      }
    }
  }, [calcData.camera, calcData.resolution]);

  useEffect(() => {
    if (calcData.camera && calcData.resolution && calcData.codec) {
      updateFramerateForCodec(calcData.camera, calcData.resolution, calcData.codec);
    }
  }, [calcData.camera, calcData.resolution, calcData.codec]);

  const updateFramerateForCodec = (camera, resolution, codec) => {
    const framerates = getFrameratesForCamera(camera, resolution, codec);
    setAvailableFramerates(framerates);
    
    if (!framerates.includes(calcData.framerate)) {
      const defaultFramerate = '23.976';
      const newFramerate = framerates.includes(defaultFramerate) ? defaultFramerate : framerates[0] || '';
      setCalcData(prev => ({ ...prev, framerate: newFramerate }));
    }
  };


  // Modify the handleInputChange function to handle brand and camera changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === 'duration') {
      setDurationInput(value);
    } else if (name === 'fileSize') {
      setFileSizeInput(value);
    } else if (['resolution', 'codec', 'framerate', 'brand', 'camera'].includes(name)) {
      setCalcData(prevData => ({
        ...prevData,
        [name]: value
      }));
      
      // If brand or camera changes, reset dependent fields
      if (name === 'brand') {
        setCalcData(prevData => ({
          ...prevData,
          camera: '',
          resolution: '',
          codec: '',
          framerate: ''
        }));
      } else if (name === 'camera') {
        setCalcData(prevData => ({
          ...prevData,
          resolution: '',
          codec: '',
          framerate: ''
        }));
      }
      
      // Recalculate based on the current lock state or duration by default
      if (calculationMode === 'fileSize') {
        // If file size is locked, recalculate duration
        const duration = calculateDuration({ ...calcData, [name]: value, fileSize: parseFloat(fileSizeInput.split(' ')[0]) }, fileSizeInput);
        setDurationInput(duration);
        setCalcData(prevData => ({
          ...prevData,
          duration: duration,
          [name]: value
        }));
      } else if (!calculationMode) { // When nothing is locked
        // Recalculate file size based on current duration
        const { size, unit } = calculateFileSize();
        setFileSizeInput(`${size} ${unit}`);
        setCalcData(prevData => ({
          ...prevData,
          fileSize: size,
          [name]: value
        }));
        setFileSizeUnit(unit);
      }
    }
  };

  // Function to format duration
  const formatDuration = (value) => {
    const parts = value.split(':').filter(part => part !== '');
    while (parts.length < 4) {
      parts.unshift('00');
    }
    return parts.join(':');
  };

  // Handler for duration focus
  const handleDurationFocus = () => {
    if (!isDurationLocked) {
      setDurationInput('');
    }
  };

  // Function to parse duration input
  const parseDuration = (input, framerate) => {
    // Replace all periods with ':00' to ensure they become two zeros
    input = input.replace(/\./g, ':00');

    if (!input || input.trim() === '') {
      return new Timecode(0, 0, 0, 0, framerate).toString();
    }

    input = input.trim().toLowerCase();

    // Handle single number input - interpret as seconds by default
    if (/^\d+$/.test(input)) {
      const seconds = parseInt(input, 10);
      const hours = Math.floor(seconds / 3600);
      const minutes = Math.floor((seconds % 3600) / 60);
      const remainingSeconds = seconds % 60;
      
      return new Timecode(hours, minutes, remainingSeconds, 0, framerate).toString();
    }

    // Handle input with colons after replacing periods
    if (/^\d+([:]\d+)*$/.test(input)) {
      const parts = input.split(':').map(Number);
      while (parts.length < 4) parts.unshift(0);
      let [hours, minutes, seconds, frames] = parts;

      // Adjust for overflow
      if (frames >= framerate) {
        seconds += Math.floor(frames / framerate);
        frames %= framerate;
      }
      if (seconds >= 60) {
        minutes += Math.floor(seconds / 60);
        seconds %= 60;
      }
      if (minutes >= 60) {
        hours += Math.floor(minutes / 60);
        minutes %= 60;
      }

      // Ensure at least 1 frame
      const totalFrames = Math.max(1, (hours * 3600 + minutes * 60 + seconds) * framerate + frames);
      
      return Timecode.fromFrames(totalFrames, framerate).toString();
    }

    // Define regex patterns for different time units
    const patterns = {
      week: /(\d+)\s*(?:w|wk|wks|week|weeks)/i,
      day: /(\d+)\s*(?:d|dy|dys|day|days)/i,
      hour: /(\d+)\s*(?:h|hr|hrs|hour|hours)/i,
      minute: /(\d+)\s*(?:m|min|mins|minute|minutes)/i,
      second: /(\d+)\s*(?:s|sec|secs|second|seconds)/i,
      frame: /(\d+)\s*(?:f|fr|frm|frms|frame|frames)/i
    };

    let totalFrames = 0;

    Object.entries(patterns).forEach(([unit, pattern]) => {
      const match = input.match(pattern);
      if (match) {
        const value = parseInt(match[1]);
        switch (unit) {
          case 'week': totalFrames += value * 7 * 24 * 3600 * framerate; break;
          case 'day': totalFrames += value * 24 * 3600 * framerate; break;
          case 'hour': totalFrames += value * 3600 * framerate; break;
          case 'minute': totalFrames += value * 60 * framerate; break;
          case 'second': totalFrames += value * framerate; break;
          case 'frame': totalFrames += value; break;
        }
      }
    });

    if (totalFrames === 0) {
      // If no valid time units were found, try parsing as a single number (assume seconds)
      const totalSeconds = parseFloat(input);
      if (!isNaN(totalSeconds)) {
        totalFrames = Math.round(totalSeconds * framerate);
      } else {
        throw new Error("Invalid duration format");
      }
    }

    return Timecode.fromFrames(totalFrames, framerate).toString();
  };

  const handleShare = async () => {
    const shareUrl = new URL(window.location.href);
    shareUrl.searchParams.set('brand', calcData.brand);
    shareUrl.searchParams.set('camera', calcData.camera);
    shareUrl.searchParams.set('resolution', calcData.resolution);
    shareUrl.searchParams.set('codec', calcData.codec);
    shareUrl.searchParams.set('framerate', calcData.framerate);
    shareUrl.searchParams.set('duration', durationInput);
    shareUrl.searchParams.set('fileSize', fileSizeInput);
    if (calculationMode) {
      shareUrl.searchParams.set('mode', calculationMode);
    }

    const shareButtonText = `${calcData.brand} ${calcData.camera} - ${calcData.resolution} \n${calcData.codec} @ ${calcData.framerate}fps \n${durationInput} = ${fileSizeInput}`;
    
    try {
      const result = await handleShareOnDevice(
        'TBD Post DataCalc',
        `Check out this camera calculation: ${shareButtonText}`,
        shareUrl.toString()
      );

      if (result?.success) {
        setShareButtonText(<SlLike />);
        setTimeout(() => setShareButtonText(<SlPaperPlane />), 500);
      }
      return result;
    } catch (error) {
      console.error('Share failed:', error);
      return { success: false, method: 'none' };
    }
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (params.has('duration')) {
      const newCalcData = {
        duration: params.get('duration') || '01:00:00:00',
        brand: params.get('brand') || 'ARRI',
        camera: params.get('camera') || 'ALEXA  35',
        resolution: params.get('resolution') || '4.6K 3:2 Open Gate (4608 x 3164)',
        codec: params.get('codec') || 'ARRIRAW  HDE',
        framerate: params.get('framerate') || '23.976',
      };
      setCalcData(newCalcData);
      setDurationInput(params.get('duration') || '01:00:00:00');
      setFileSizeInput(params.get('fileSize') || '1.915 TB');
    }
  }, []);

  // Handler for duration blur event
  const handleDurationBlur = () => {
    if (durationInput.trim() === '') {
      setDurationInput('');
      if (!isDurationLocked) {
        setCalcData(prevData => ({ ...prevData, duration: '' }));
      }
    } else {
      try {
        const parsedDuration = parseDuration(durationInput, parseFloat(calcData.framerate));
        setDurationInput(parsedDuration);
        if (!isDurationLocked) {
          setCalcData(prevData => ({
            ...prevData,
            duration: parsedDuration
          }));
          // Recalculate file size if needed
          if (!isFileSizeLocked) {
            const { size, unit } = calculateFileSize();
            setFileSizeInput(`${size} ${unit}`);
            setFileSizeUnit(unit);
          }
        }
      } catch (error) {
        console.error("Invalid duration format");
        setDurationInput('');
        if (!isDurationLocked) {
          setCalcData(prevData => ({ ...prevData, duration: '' }));
        }
      }
    }
  };

  // Handler for duration key press
  const handleDurationKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (!isDurationLocked) {
        try {
          const parsedDuration = parseDuration(durationInput, parseFloat(calcData.framerate));
          setDurationInput(parsedDuration);
          setCalcData(prev => ({
            ...prev,
            duration: parsedDuration
          }));
          // Recalculate file size based on the parsed duration
          if (!isFileSizeLocked) {
            const { size, unit } = calculateFileSize({ ...calcData, duration: parsedDuration });
            setFileSizeInput(`${size} ${unit}`);
            setCalcData(prevData => ({
              ...prevData,
              fileSize: size
            }));
            setFileSizeUnit(unit);
          }
          // Blur the input field
          e.target.blur();
        } catch (error) {
          console.error("Invalid duration format");
          // Optionally, notify the user or revert to the previous valid duration
        }
      }
    }
  };

  const handleWheel = (e, options, currentValue, setterFunction) => {
    e.preventDefault();
    const index = options.indexOf(currentValue);
    const sensitivity = 0.025;
    const scrollAmount = -e.deltaY * sensitivity;
    
    let newIndex = index + Math.sign(scrollAmount);
    newIndex = Math.max(0, Math.min(newIndex, options.length - 1));
    
    if (newIndex !== index) {
      setterFunction(options[newIndex]);
    }
  };

  const handleDurationWheel = (e) => {
    e.preventDefault();
    if (isDurationLocked) return; // Immediately return if duration is locked

    const sensitivity = 0.025;
    const scrollAmount = -e.deltaY * sensitivity; // Note the negative sign here
    
    const rect = e.currentTarget.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const width = rect.width;
    
    let [hours, minutes, seconds, frames] = calcData.duration.split(':').map(Number);
    const framerate = parseFloat(calcData.framerate);
    
    if (x < width * 0.25) {
      // Scrolling hours
      hours += Math.sign(scrollAmount);
      hours = Math.max(0, hours);
    } else if (x < width * 0.5) {
      // Scrolling minutes
      minutes += Math.sign(scrollAmount);
      if (minutes < 0) {
        if (hours > 0) {
          hours--;
          minutes = 59;
        } else {
          minutes = 0;
        }
      } else if (minutes >= 60) {
        hours++;
        minutes = 0;
      }
    } else if (x < width * 0.75) {
      // Scrolling seconds
      seconds += Math.sign(scrollAmount);
      if (seconds < 0) {
        if (minutes > 0) {
          minutes--;
          seconds += 60;
        } else if (hours > 0) {
          hours--;
          minutes = 59;
          seconds += 60;
        } else {
          seconds = 0;
        }
      } else if (seconds >= 60) {
        minutes++;
        seconds = 0;
        if (minutes >= 60) {
          hours++;
          minutes -= 60;
        }
      }
    } else {
      // Scrolling frames
      frames += Math.sign(scrollAmount);
      if (frames < 0) {
        if (seconds > 0) {
          seconds--;
          frames = Math.floor(framerate) - 1;
        } else if (minutes > 0) {
          minutes--;
          seconds = 59;
          frames = Math.floor(framerate) - 1;
        } else if (hours > 0) {
          hours--;
          minutes = 59;
          seconds = 59;
          frames += Math.floor(framerate) - 1;
        } else {
          frames = 0;
        }
      } else if (frames >= Math.floor(framerate)) {
        seconds++;
        frames = 0;
        if (seconds >= 60) {
          minutes++;
          seconds -= 60;
          if (minutes >= 60) {
            hours++;
            minutes -= 60;
          }
        }
      }
    }

    const newDuration = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}:${frames.toString().padStart(2, '0')}`;
    setDurationInput(newDuration);
    setCalcData(prevData => ({ ...prevData, duration: newDuration }));

    // Recalculate file size
    const { size, unit } = calculateFileSize();
    setFileSizeInput(`${size} ${unit}`);
    setCalcData(prevData => ({
      ...prevData,
      fileSize: size
    }));
    setFileSizeUnit(unit);
  };

  const calculateFileSize = (data = calcData) => {
    try {
      console.log("Starting file size calculation with data:", data);

      const [hours, minutes, seconds, frames] = data.duration.split(':').map(Number);
      const totalFrames = (hours * 3600 + minutes * 60 + seconds) * parseFloat(data.framerate) + frames;
      console.log("Total frames:", totalFrames);

      let resolutionValue;
      const resolutionMatch = data.resolution.match(/\((\d+)\s*x\s*(\d+)\)/);
      if (resolutionMatch) {
        const [, width, height] = resolutionMatch;
        resolutionValue = parseInt(width) * parseInt(height);
        console.log("Resolution parsed from string:", width, "x", height);
      } else {
        const resolutionData = resolutions[data.resolution];
        if (!resolutionData || !resolutionData.dimensions) {
          throw new Error(`Invalid resolution: ${data.resolution}`);
        }
        const [width, height] = resolutionData.dimensions;
        resolutionValue = width * height;
      }
      console.log("Resolution value:", resolutionValue);

      const selectedCodec = codecs.find(codec => codec.name === data.codec);
      console.log("Selected codec:", selectedCodec);
      if (!selectedCodec) {
        throw new Error(`Invalid codec: ${data.codec}`);
      }

      const megapixels = resolutionValue; // Convert to megapixels
      const datarate = selectedCodec.datarate;
      const fileSizeGB = (totalFrames * megapixels * datarate) / 8 / 1024;
      console.log("Calculated file size in GB:", fileSizeGB);

      const { size, unit } = formatFileSize(fileSizeGB);
      console.log("Formatted file size:", size, unit);

      return { size, unit };
    } catch (error) {
      console.error("Error calculating file size:", error.message);
      return { size: '0', unit: 'B' };
    }
  };

  // Helper function to format file size
  const formatFileSize = (sizeInGB) => {
    const units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB'];
    let size = sizeInGB * 1024 * 1024 * 1024; // Convert GB to bytes
    let unitIndex = 0;

    while (size >= 1024 && unitIndex < units.length - 1) {
      size /= 1024;
      unitIndex++;
    }

    return {
      size: size.toFixed(3),
      unit: units[unitIndex]
    };
  };

  // 8. Modify useEffect to handle recalculations based on calculationMode
  useEffect(() => {
    // Skip calculations if the resolution is set to 'Custom' and the custom resolution is not yet confirmed
    if (calcData.resolution === 'Custom' && !calcData.customResolution) {
      return;
    }

    recalculateBasedOnLockState();
  }, [
    calcData.resolution,
    calcData.codec,
    calcData.framerate,
    calcData.duration,
    calcData.fileSize,
    isDurationLocked,
    isFileSizeLocked,
    calculationMode
  ]);

  useEffect(() => {
    const resolutionElement = resolutionRef.current;
    const framerateElement = framerateRef.current;
    const codecElement = codecRef.current;

    const elements = [
      { ref: resolutionElement, handler: handleResolutionWheel },
      { ref: framerateElement, handler: handleFramerateWheel },
      { ref: codecElement, handler: handleCodecWheel },
    ];

    elements.forEach(({ ref, handler }) => {
      if (ref) {
        ref.addEventListener('wheel', handler, { passive: false });
      }
    });

    return () => {
      elements.forEach(({ ref, handler }) => {
        if (ref) {
          ref.removeEventListener('wheel', handler);
        }
      });
    };
  }, [calcData.resolution, calcData.framerate, calcData.codec]);

  useEffect(() => {
    const durationElement = durationRef.current;

    const wheelHandler = (e) => {
      if (!isDurationLocked) {
        handleDurationWheel(e);
      }
    };

    if (durationElement) {
      durationElement.addEventListener('wheel', wheelHandler, { passive: false });
    }

    return () => {
      if (durationElement) {
        durationElement.removeEventListener('wheel', wheelHandler);
      }
    };
  }, [calcData.duration, calcData.framerate, isDurationLocked]);

  const handleFileSizeClick = () => {
    if (isMobile) return;

    const fileSizeText = `${calcData.fileSize} ${fileSizeUnit}`;
    navigator.clipboard.writeText(fileSizeText).then(() => {
    }).catch(err => {
      console.error('Failed to copy: ', err);
    });
  };

  const handleLogClick = () => {
    const logEntry = `${calcData.brand} ${calcData.camera} - ${calcData.resolution} \n${calcData.codec} @ ${calcData.framerate}fps (${calculateBitrate()}) \n${calcData.duration} = ${fileSizeInput}\r\n`;
    addLog(logEntry);

    setLogButtonText(<SlLike />);
    setTimeout(() => setLogButtonText(<SlNote />), 500);

    // Copy to clipboard functionality
    if (navigator.clipboard) {
      navigator.clipboard.writeText(logEntry)
        .then(() => {
          // You can add a visual feedback here if needed
        })
        .catch(err => {
          console.error('Failed to copy log entry: ', err);
        });
    }
  };

  const copyLogToClipboard = (log, index) => {
    if (isMobile) return; 

    const logWithLineBreak = `${log}\r\n`; // Add line break

    navigator.clipboard.writeText(logWithLineBreak).then(() => {
      setCopiedLogIndex(index);
      setTimeout(() => setCopiedLogIndex(null), 3000);
    }).catch(err => {
      console.error('Failed to copy log: ', err);
      setCopiedLogIndex(null);
    });
  };

  const handleBrandWheel = (e) => {
    handleWheel(
      e,
      brands,
      calcData.brand,
      (newBrand) => {
        setCalcData(prev => ({ ...prev, brand: newBrand }));
        recalculateBasedOnLockState();
      }
    );
  };

  const handleCameraWheel = (e) => {
    handleWheel(
      e,
      availableCameras,
      calcData.camera,
      (newCamera) => {
        setCalcData(prev => ({ ...prev, camera: newCamera }));
        recalculateBasedOnLockState();
      }
    );
  };

  const handleResolutionWheel = (e) => {
    handleWheel(
      e, 
      availableResolutions, 
      calcData.resolution, 
      (newResolution) => {
        setCalcData(prev => ({ ...prev, resolution: newResolution }));
        recalculateBasedOnLockState(newResolution);
      }
    );
  };

  const handleFramerateWheel = (e) => {
    handleWheel(
      e, 
      availableFramerates, 
      calcData.framerate, 
      (newFramerate) => {
        setCalcData(prev => ({ ...prev, framerate: newFramerate }));
        recalculateBasedOnLockState();
      }
    );
  };

  // Modify the handleCodecWheel function
  const handleCodecWheel = (e) => {
    e.preventDefault();
    if (availableCodecs.length === 0) return;

    const delta = Math.sign(e.deltaY);
    const currentIndex = availableCodecs.indexOf(calcData.codec);
    let newIndex = currentIndex + delta;

    // Ensure the new index is within bounds without loolockg
    newIndex = Math.max(0, Math.min(newIndex, availableCodecs.length - 1));

    // Only update if the index has changed
    if (newIndex !== currentIndex) {
      const newCodec = availableCodecs[newIndex];
      setCalcData(prev => ({ ...prev, codec: newCodec }));
      updateFramerateForCodec(calcData.camera, calcData.resolution, newCodec);
    }
  };

  // Modify the useEffect for codec ref
  useEffect(() => {
    const codecElement = codecRef.current;

    const wheelHandler = (e) => {
      handleCodecWheel(e);
    };

    if (codecElement) {
      codecElement.addEventListener('wheel', wheelHandler, { passive: false });
    }

    return () => {
      if (codecElement) {
        codecElement.removeEventListener('wheel', wheelHandler);
      }
    };
  }, [calcData.camera, calcData.resolution, calcData.codec, availableCodecs]);

  // Add a new useEffect to ensure codec is always valid
  useEffect(() => {
    if (availableCodecs.length > 0 && !availableCodecs.includes(calcData.codec)) {
      const newCodec = availableCodecs[0];
      setCalcData(prev => ({ ...prev, codec: newCodec }));
      updateFramerateForCodec(calcData.camera, calcData.resolution, newCodec);
    }
  }, [calcData.camera, calcData.resolution, calcData.codec, availableCodecs]);

  const handleDeleteLog = (index) => {
    setLogs(prevLogs => prevLogs.filter((_, i) => i !== index));
  };

  const defaultValues = {
    duration: "01:00:00:00",
    brand: 'ARRI',
    camera: 'ALEXA  35',
    resolution: '4.6K 3:2 Open Gate (4608 x 3164)',
    codec: 'ARRIRAW  HDE',
    framerate: "23.976",
    fileSize: '1.915 TB'
  };

  const handleReset = () => {
    // Only reset duration and file size related states
    setDurationInput("01:00:00:00");
    
    // Remove locks and calculation mode
    setIsDurationLocked(false);
    setIsFileSizeLocked(false);
    setCalculationMode(null);
    
    // Update calcData with only duration reset
    setCalcData(prevData => ({
        ...prevData,
        duration: "01:00:00:00"
    }));

    // Show reset confirmation
    setResetButtonText(<SlLike />);
    setTimeout(() => setResetButtonText(<SlRefresh />), 500);
  };

  useEffect(() => {
    if (isMobile) {
      disableScroll();
      return () => enableScroll();
    }
  }, [isMobile]);

  // 1. Update handleCopyCameraToFileSize to remove navigator.clipboard.writeText
  const handleCopyCameraToFileSize = (text, result) => {
    if (result) {
      setCopyDurationText(<SlLike />);
    } else {
      setCopyDurationText('Error');
    }
    setTimeout(() => setCopyDurationText(<SlDocs />), 500);
  };

  // 1. Update handleCopyFileSize to remove navigator.clipboard.writeText
  const handleCopyFileSize = (text, result) => {
    if (result) {
      setCopyFileSizeText(<SlLike />);
    } else {
      setCopyFileSizeText('Error');
    }
    setTimeout(() => setCopyFileSizeText(<SlDocs />), 500);
  };

  // 3. Update handleTouchStart to allow default behavior for select fields
  const handleTouchStart = useCallback((e, field) => {
    if ((field === 'fileSize' && isFileSizeLocked) || (field === 'duration' && isDurationLocked)) return;
    
    e.preventDefault();
    const touch = e.touches[0];
    const startY = touch.clientY;
    const rect = e.target.getBoundingClientRect();
    const touchX = touch.clientX - rect.left;
    const fieldWidth = rect.width;
    let startValue;

    if (field === 'fileSize') {
      const parsedSize = parseFileSize(fileSizeInput);
      startValue = parsedSize ? parseFloat(parsedSize.size) : 0;
    } else if (field === 'duration') {
      const [hours, minutes, seconds, frames] = durationInput.split(':').map(Number);
      startValue = {
        hours,
        minutes,
        seconds,
        frames
      };
    }

    const handleTouchMove = (e) => {
      e.preventDefault();
      const touch = e.touches[0];
      const currentY = touch.clientY;
      const diffY = startY - currentY;
      const sensitivity = 0.1;

      if (field === 'duration') {
        const segment = Math.floor(touchX / (fieldWidth / 4)); // Split into 4 segments
        const framerate = parseFloat(calcData.framerate);
        
        // Calculate change based on segment
        let change = Math.round(diffY * sensitivity);
        let { hours, minutes, seconds, frames } = startValue;
        
        switch(segment) {
          case 0: // Hours
            hours = Math.max(0, hours - change);
            break;
          case 1: // Minutes
            minutes -= change;
            while (minutes < 0) {
              if (hours > 0) {
                hours--;
                minutes += 60;
              } else {
                minutes = 0;
              }
            }
            while (minutes >= 60) {
              hours++;
              minutes -= 60;
            }
            break;
          case 2: // Seconds
            seconds -= change;
            while (seconds < 0) {
              if (minutes > 0) {
                minutes--;
                seconds += 60;
              } else if (hours > 0) {
                hours--;
                minutes = 59;
                seconds += 60;
              } else {
                seconds = 0;
              }
            }
            while (seconds >= 60) {
              minutes++;
              seconds -= 60;
              if (minutes >= 60) {
                hours++;
                minutes -= 60;
              }
            }
            break;
          case 3: // Frames
            frames -= change;
            while (frames < 0) {
              if (seconds > 0) {
                seconds--;
                frames += Math.floor(framerate);
              } else if (minutes > 0) {
                minutes--;
                seconds = 59;
                frames += Math.floor(framerate);
              } else if (hours > 0) {
                hours--;
                minutes = 59;
                seconds = 59;
                frames += Math.floor(framerate);
              } else {
                frames = 0;
              }
            }
            while (frames >= framerate) {
              seconds++;
              frames -= Math.floor(framerate);
              if (seconds >= 60) {
                minutes++;
                seconds -= 60;
                if (minutes >= 60) {
                  hours++;
                  minutes -= 60;
                }
              }
            }
            break;
        }

        const newDuration = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}:${frames.toString().padStart(2, '0')}`;

        setDurationInput(newDuration);
        setCalcData(prevData => ({
          ...prevData,
          duration: newDuration
        }));

        // Recalculate file size
        const { size, unit } = calculateFileSize({ ...calcData, duration: newDuration });
        setFileSizeInput(`${size} ${unit}`);
        setCalcData(prevData => ({
          ...prevData,
          fileSize: size
        }));
        setFileSizeUnit(unit);
      }
      // ... existing fileSize handling ...
    };

    const handleTouchEnd = () => {
      document.removeEventListener('touchmove', handleTouchMove);
      document.removeEventListener('touchend', handleTouchEnd);
    };

    document.addEventListener('touchmove', handleTouchMove, { passive: false });
    document.addEventListener('touchend', handleTouchEnd);
  }, [calcData, fileSizeInput, durationInput, isFileSizeLocked, isDurationLocked, calculateFileSize, fileSizeUnit]);

  // Updated parseFileSize function to handle more input permutations
  const parseFileSize = (input) => {
    // Remove any whitespace and convert to lowercase
    const cleanInput = input.replace(/\s+/g, '').toLowerCase();
  
    // Define unit patterns
    const unitPatterns = {
      'pb': ['p', 'ps', 'pb', 'pbs', 'pe', 'pet', 'pets', 'peta', 'petas', 'petabyte', 'petabytes'],
      'tb': ['t', 'ts', 'tb', 'tbs', 'te', 'ter', 'ters', 'tera', 'teras', 'terabyte', 'terabytes'],
      'gb': ['g', 'gs', 'gb', 'gbs', 'ge', 'gig', 'gigs', 'giga', 'gigas', 'gigabyte', 'gigabytes'],
      'mb': ['m', 'ms', 'mb', 'mbs', 'me', 'meg', 'megs', 'mega', 'megas', 'megabyte', 'megabytes'],
      'kb': ['k', 'ks', 'kb', 'kbs', 'ki', 'kil', 'kils', 'kilo', 'kilos', 'kilobyte', 'kilobytes'],
      'b': ['b', 'bs', 'bi', 'by', 'byte', 'bytes']
    };
  
    // Create a regex pattern that matches any of the unit patterns
    const unitRegexPattern = Object.values(unitPatterns).flat().join('|');
    
    // Match the input against the pattern
    const match = cleanInput.match(new RegExp(`^(\\d+(?:\\.\\d+)?)(${unitRegexPattern})$`));
  
    if (match) {
      const size = parseFloat(match[1]);
      const unitLower = match[2];
  
      // Determine the standardized unit
      for (const [standardUnit, patterns] of Object.entries(unitPatterns)) {
        if (patterns.includes(unitLower)) {
          return { size, unit: standardUnit.toUpperCase() };
        }
      }
    }
  
    return null;
  };

  // Update handleFileSizeFocus to clear the input and show placeholder text
  const handleFileSizeFocus = () => {
    if (!isFileSizeLocked) {
      setFileSizeInput('');
    }
  };

  // Updated handleFileSizeBlur function to parse input and calculate duration
  const handleFileSizeBlur = () => {
    if (fileSizeInput.trim() === '') {
      setFileSizeInput('');
      if (!isFileSizeLocked) {
        setCalcData(prevData => ({ ...prevData, fileSize: '' }));
      }
    } else {
      const parsedSize = parseFileSize(fileSizeInput);
      if (parsedSize !== null) {
        const formattedSize = `${parsedSize.size} ${parsedSize.unit}`;
        setFileSizeInput(formattedSize);
        if (!isFileSizeLocked) {
          setCalcData(prevData => ({
            ...prevData,
            fileSize: parsedSize.size,
            fileSizeUnit: parsedSize.unit
          }));
          
          // Calculate duration based on the parsed file size
          if (!isDurationLocked) {
            const duration = calculateDuration({ 
              ...calcData, 
              fileSize: parsedSize.size, 
              fileSizeUnit: parsedSize.unit 
            }, formattedSize);
            setDurationInput(duration);
            setCalcData(prevData => ({
              ...prevData,
              duration: duration
            }));
          }
        }
      } else {
        console.error("Invalid file size format");
        // Optionally, revert to previous valid input or notify the user
      }
    }
  };

  // Updated handleFileSizeKeyPress to trigger blur on Enter key
  const handleFileSizeKeyPress = (e) => {
    if (e.key === 'Enter' || e.key === 'Tab') {
      e.preventDefault();
      if (!isFileSizeLocked) {
        const parsedSize = parseFileSize(fileSizeInput);
        if (parsedSize !== null) {
          const formattedSize = `${parsedSize.size} ${parsedSize.unit}`;
          setFileSizeInput(formattedSize);
          setCalcData(prevData => ({
            ...prevData,
            fileSize: parsedSize.size,
            fileSizeUnit: parsedSize.unit
          }));
          
          // Calculate duration based on the parsed file size
          if (!isDurationLocked) {
            const duration = calculateDuration({ 
              ...calcData, 
              fileSize: parsedSize.size, 
              fileSizeUnit: parsedSize.unit 
            }, formattedSize);
            setDurationInput(duration);
            setCalcData(prevData => ({
              ...prevData,
              duration: duration
            }));
          }
        }
        e.target.blur(); // Remove focus from the input
      }
    }
  };

// Helper function to recalculate based on lock state
  const recalculateBasedOnLockState = (updatedResolution = calcData.resolution) => {
    const updatedCalcData = { ...calcData, resolution: updatedResolution };
    
    if (calculationMode === 'duration' && isDurationLocked) {
      // If duration is locked, recalculate file size based on duration
      const { size, unit } = calculateFileSize(updatedCalcData);
      setFileSizeInput(`${size} ${unit}`);
      setCalcData(prevData => ({
        ...prevData,
        fileSize: size
      }));
      setFileSizeUnit(unit);
    } else if (calculationMode === 'fileSize' && isFileSizeLocked) {
      // If file size is locked, recalculate duration based on file size
      const duration = calculateDuration(updatedCalcData, fileSizeInput);
      setDurationInput(duration);
      setCalcData(prevData => ({
        ...prevData,
        duration: duration
      }));
    } else {
      // If nothing is locked, default to recalculating file size based on duration
      const { size, unit } = calculateFileSize(updatedCalcData);
      setFileSizeInput(`${size} ${unit}`);
      setCalcData(prevData => ({
        ...prevData,
        fileSize: size
      }));
      setFileSizeUnit(unit);
    }
  };

   // Updated handleFileSizeWheel to implement proportional scrolling across all units
  const handleFileSizeWheel = (e) => {
    e.preventDefault();
    if (isFileSizeLocked || isDurationLocked) return;

    const delta = Math.sign(-e.deltaY); // Positive for scroll up, negative for scroll down
    const changeFactor = 1.1; // 10% change per scroll

    let parsed = parseFileSize(fileSizeInput);
    if (!parsed) return;
    let { size, unit } = parsed;
    let newSize = size;

    // Apply proportional change based on scroll direction
    if (delta > 0) {
      newSize *= changeFactor;
    } else {
      newSize /= changeFactor;
    }

    // Define the order of units
    const unitsOrder = ['KB', 'MB', 'GB', 'TB', 'PB'];
    let currentIndex = unitsOrder.indexOf(unit);

    // Adjust unit if size exceeds current unit's boundaries
    while (newSize >= 1000 && currentIndex < unitsOrder.length - 1) {
      newSize /= 1000;
      currentIndex++;
      unit = unitsOrder[currentIndex];
    }

    while (newSize < 1 && currentIndex > 0) {
      newSize *= 1000;
      currentIndex--;
      unit = unitsOrder[currentIndex];
    }

    // Format the new size based on unit for consistency
    newSize = unit === 'KB' ? Math.round(newSize) : parseFloat(newSize.toFixed(2));

    const newFileSizeInput = `${newSize} ${unit}`;
    setFileSizeInput(newFileSizeInput);

    // Update calcData immediately
    const updatedCalcData = {
      ...calcData,
      fileSize: newSize.toString(),
      fileSizeUnit: unit
    };
    setCalcData(updatedCalcData);

    // Recalculate duration based on the new file size
    const duration = calculateDuration(updatedCalcData, newFileSizeInput);
    setDurationInput(duration);
    setCalcData(prevData => ({
      ...prevData,
      duration: duration
    }));
  };

  // Ensure handleFileSizeWheel is correctly referenced in useEffect
  useEffect(() => {
    const fileSizeElement = fileSizeRef.current;

    const wheelHandler = (e) => {
      if (!isFileSizeLocked) {
        handleFileSizeWheel(e);
      }
    };

    if (fileSizeElement) {
      fileSizeElement.addEventListener('wheel', wheelHandler, { passive: false });
      fileSizeElement.addEventListener('touchmove', wheelHandler, { passive: false });
    }

    return () => {
      if (fileSizeElement) {
        fileSizeElement.removeEventListener('wheel', wheelHandler);
        fileSizeElement.removeEventListener('touchmove', wheelHandler);
      }
    };
  }, [isFileSizeLocked, handleFileSizeWheel]);

  // 3. Update the input fields in the JSX to apply styles based on lock state
  // Modify the useEffect for mobile anchoring
  useEffect(() => {
    if (isMobile) {
      const handleScroll = () => {
        if (window.scrollY > 0) {
          window.scrollTo(0, 0);
        }
      };

      window.addEventListener('scroll', handleScroll);

      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }
  }, [isMobile]);

  
  // Update commonInputClass to include direct mobile centering styles
  const commonInputClass = commonStyles.commonInputClass;
  const textCenterImportant = commonStyles.textCenterImportant;
  
  const lockButtonStyle = commonStyles.lockButtonStyle(calculationMode);


  
   // Function to get input style based on lock state
   const getInputStyle = (field) => commonStyles.getInputStyle(field, calculationMode);


  useEffect(() => {
    setAvailableCameras(getCamerasByBrand(calcData.brand));
  }, [calcData.brand]);

  useEffect(() => {
    if (calcData.camera) {
      setAvailableResolutions(getResolutionsForCamera(calcData.camera));
      setAvailableFramerates(getFrameratesForCamera(calcData.camera));
    }
  }, [calcData.camera]);

  useEffect(() => {
    if (calcData.camera && calcData.resolution) {
      setAvailableCodecs(getCodecsForCameraAndResolution(calcData.camera, calcData.resolution));
    }
  }, [calcData.camera, calcData.resolution]);

  

  useEffect(() => {
    const elements = [
      { ref: brandRef, handler: handleBrandWheel },
      { ref: cameraRef, handler: handleCameraWheel },
      { ref: resolutionRef, handler: handleResolutionWheel },
      { ref: framerateRef, handler: handleFramerateWheel },
      { ref: codecRef, handler: handleCodecWheel },
      { ref: durationRef, handler: handleDurationWheel },
      { ref: fileSizeRef, handler: handleFileSizeWheel }
    ];

    elements.forEach(({ ref, handler }) => {
      if (ref.current) {
        ref.current.addEventListener('wheel', handler, { passive: false });
      }
    });

    return () => {
      elements.forEach(({ ref, handler }) => {
        if (ref.current) {
          ref.current.removeEventListener('wheel', handler);
        }
      });
    };
  }, [calcData, handleBrandWheel, handleCameraWheel, handleResolutionWheel, 
    handleFramerateWheel, handleCodecWheel, handleDurationWheel, handleFileSizeWheel]);

  // Add these new refs
  const brandRef = useRef(null);
  const cameraRef = useRef(null);

  // Add these hooks for each dropdown
  const handleBrandTouch = useDropdownTouch(
    brands,
    calcData.brand,
    handleInputChange,
    'brand'
  );

  const handleCameraTouch = useDropdownTouch(
    availableCameras,
    calcData.camera,
    handleInputChange,
    'camera'
  );

  const handleResolutionTouch = useDropdownTouch(
    availableResolutions,
    calcData.resolution,
    handleInputChange,
    'resolution'
  );

  const handleCodecTouch = useDropdownTouch(
    availableCodecs,
    calcData.codec,
    handleInputChange,
    'codec'
  );

  const handleFramerateTouch = useDropdownTouch(
    availableFramerates,
    calcData.framerate,
    handleInputChange,
    'framerate'
  );

  // Add the calculateBitrate function inside the component
  const calculateBitrate = (data = calcData) => {
    try {
      // Get resolution dimensions
      let width, height;
      const resolutionMatch = data.resolution.match(/\((\d+)\s*x\s*(\d+)\)/);
      if (resolutionMatch) {
        [, width, height] = resolutionMatch;
      } else {
        throw new Error('Invalid resolution format');
      }

      // Get codec datarate
      const selectedCodec = codecs.find(codec => codec.name === data.codec);
      if (!selectedCodec) {
        throw new Error('Invalid codec');
      }
      const datarate = selectedCodec.datarate;

      // Calculate bitrate
      const framerate = parseFloat(data.framerate);
      const bitrateInBytes = (datarate * width * height * framerate) * 1024 * 1024;
      
      return formatBitrate(bitrateInBytes);
    } catch (error) {
      console.error('Error calculating bitrate:', error);
      return '0 MB/s';
    }
  };

  const currentSpacing = isMobile ? spacing.mobile : spacing.desktop;

  return (
    <div className={`camera-to-file-size ${currentSpacing.padding} ${colors.bgColor} ${colors.textColor}`}>
      <div className={layout.flex.column}>
        <div className={`grid ${layout.grid.lockLayout} gap-2 ${currentSpacing.fieldGap}`}>
          <div>
            <label className={`${commonStyles.labelClass} md:text-base`}>
              {isMobile ? "Duration" : "Duration (HH:MM:SS:FF)"}
            </label>
            <input
              ref={durationRef}
              type="text"
              name="duration"
              value={durationInput}
              onChange={handleInputChange}
              onFocus={handleDurationFocus}
              onBlur={handleDurationBlur}
              onKeyPress={handleDurationKeyPress}
              onWheel={handleDurationWheel}
              onTouchStart={(e) => handleTouchStart(e, 'duration')}
              placeholder={isDurationLocked ? '' : "ex: 01:00:00:00 or 16 hrs"}
              className={`${getInputStyle('duration')} text-sm md:text-base`}
              disabled={isDurationLocked}
            />
          </div>

          <div className="flex items-end relative">
            <button
              type="button"
              onClick={() => {
                if (calculationMode === null) {
                  setIsDurationLocked(true);
                  setIsFileSizeLocked(false);
                  setCalculationMode('duration');
                } else if (calculationMode === 'duration') {
                  setIsDurationLocked(false);
                  setIsFileSizeLocked(true);
                  setCalculationMode('fileSize');
                } else {
                  setIsDurationLocked(false);
                  setIsFileSizeLocked(false);
                  setCalculationMode(null);
                }
              }}
              className={`${commonStyles.lockButtonStyle(calculationMode)} relative`}
            >
              {calculationMode ? (
                <FaLock className={`${colors.lockIconColor} transform scale-75`} />
              ) : (
                <FaUnlockAlt className={colors.unlockIconColor} />
              )}
              {calculationMode === 'duration' && (
                <FaAngleLeft className={`absolute -left-1 top-1/2 -translate-y-1/2 ${colors.arrowColor} text-lg`} />
              )}
              {calculationMode === 'fileSize' && (
                <FaAngleRight className={`absolute -right-1 top-1/2 -translate-y-1/2 ${colors.arrowColor} text-lg`} />
              )}
            </button>
          </div>

          <div>
            <label className={`${commonStyles.labelClass} md:text-base`}>File Size</label>
            <input
              ref={fileSizeRef}
              type="text"
              name="fileSize"
              value={fileSizeInput}
              onChange={handleInputChange}
              onFocus={handleFileSizeFocus}
              onBlur={handleFileSizeBlur}
              onKeyPress={handleFileSizeKeyPress}
              onWheel={handleFileSizeWheel}
              onTouchStart={(e) => handleTouchStart(e, 'fileSize')}
              placeholder={isFileSizeLocked ? '' : "ex: 512 GB"}
              className={`${getInputStyle('fileSize')} text-sm md:text-base`}
              disabled={isFileSizeLocked}
            />
          </div>
        </div>

        <div className={`grid ${layout.grid.brandCamera} gap-2 ${currentSpacing.fieldGap}`}>
          <div>
            <label className={`${commonStyles.labelClass} md:text-base`}>Brand</label>
            <select
              ref={brandRef}
              name="brand"
              value={calcData.brand}
              onChange={handleInputChange}
              onTouchStart={handleBrandTouch}
              className={`${commonInputClass} text-sm md:text-base`}
            >
              {brands.map(brand => (
                <option key={brand} value={brand}>{brand}</option>
              ))}
            </select>
          </div>
          <div>
            <label className={`${commonStyles.labelClass} md:text-base`}>Camera</label>
            <select
              ref={cameraRef}
              name="camera"
              value={calcData.camera}
              onChange={handleInputChange}
              onTouchStart={handleCameraTouch}
              className={`${commonInputClass} text-sm md:text-base`}
            >
              {availableCameras.map(camera => (
                <option key={camera} value={camera}>{camera}</option>
              ))}
            </select>
          </div>
        </div>

        <div className={`grid grid-cols-[2fr,1fr] gap-2 ${currentSpacing.fieldGap}`}>
          <div>
            <label className={`${commonStyles.labelClass} md:text-base`}>Resolution</label>
            <select
              ref={resolutionRef}
              name="resolution"
              value={calcData.resolution}
              onChange={handleInputChange}
              onTouchStart={handleResolutionTouch}
              className={`${commonInputClass} text-sm md:text-base`}
            >
              {availableResolutions.map(resolution => (
                <option key={resolution} value={resolution}>{resolution}</option>
              ))}
            </select>
          </div>
          <div>
            <label className={`${commonStyles.labelClass} md:text-base`}>Framerate</label>
            <select
              ref={framerateRef}
              name="framerate"
              value={calcData.framerate}
              onChange={handleInputChange}
              onTouchStart={handleFramerateTouch}
              className={`${commonInputClass} text-sm md:text-base`}
            >
              {availableFramerates.map(framerate => (
                <option key={framerate} value={framerate}>{framerate}</option>
              ))}
            </select>
          </div>
        </div>

        <div className={`grid ${layout.grid.codecBitrate} gap-2 ${currentSpacing.fieldGap}`}>
          <div>
            <label className={`${commonStyles.labelClass} md:text-base`}>Codec</label>
            <select
              ref={codecRef}
              name="codec"
              value={calcData.codec}
              onChange={handleInputChange}
              onTouchStart={handleCodecTouch}
              className={`${commonInputClass} text-sm md:text-base`}
            >
              {availableCodecs.map((codec, index) => (
                <option key={`${codec}-${index}`} value={codec}>{codec}</option>
              ))}
            </select>
          </div>
          <div>
            <label className={`${commonStyles.labelClass} md:text-base`}>Bitrate</label>
            <input
              type="text"
              value={calculateBitrate()}
              readOnly
              className={`${commonInputClass} ${commonStyles.readOnlyInput} text-sm md:text-base`}
              aria-label="Calculated bitrate"
            />
          </div>
        </div>

        <CalculatorButtons 
          onLog={handleLogClick}
          onCopy={handleCopyCameraToFileSize}
          onShare={handleShare}
          onReset={handleReset}
          copyText={`${calcData.brand} ${calcData.camera} - ${calcData.resolution} \n${calcData.codec} @ ${calcData.framerate}fps (${calculateBitrate()}) \n${calcData.duration} = ${fileSizeInput}\r\n`}
          logButtonIcon={logButtonText}
          copyButtonIcon={copyDurationText}
          shareButtonIcon={shareButtonText}
          resetButtonIcon={resetButtonText}
        />
      </div>
    </div>
  );
};

export default CameraToFileSize;