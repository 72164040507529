import { AiFillPicture } from "react-icons/ai";
import { BsFillAspectRatioFill, BsFillStopwatchFill } from 'react-icons/bs';
import { FaClock, FaClipboardList, FaHistory, FaPlayCircle } from 'react-icons/fa';
import { FaVideo, FaCalculator } from 'react-icons/fa6';
import { PiFilmReelFill } from 'react-icons/pi';
import { RiFileEditFill } from 'react-icons/ri';
import { HiSparkles } from 'react-icons/hi2';
import { BiTransfer } from 'react-icons/bi';
import { MdInsertChart } from 'react-icons/md';

// Add near the top of the file
console.log('Current environment:', process.env.REACT_APP_ENVIRONMENT);

// Helper to check if we're in beta environment
export const isInBetaEnvironment = () => {
  // Check if we're on beta.data-calc.com
  if (window.location.hostname === 'beta.data-calc.com') {
    return true;
  }
  // Check if we're in local development beta mode
  if (process.env.REACT_APP_ENVIRONMENT === 'beta' && window.location.hostname === 'localhost') {
    return true;
  }
  // Check for explicit beta environment setting (for other cases)
  return process.env.REACT_APP_ENVIRONMENT === 'beta';
};

// Helper to determine if "Beta" pill should be shown
export const shouldShowBetaPill = (calculator) => {
  // Show Beta pill if the calculator is marked as beta, regardless of environment
  return calculator.beta;
};

// Helper to determine if "Coming Soon" pill should be shown
export const shouldShowComingSoonPill = (calculator) => {
  const isBeta = isInBetaEnvironment();
  // In beta environment: don't show Coming Soon for beta features
  if (isBeta && calculator.beta) {
    return false;
  }
  // Show Coming Soon pill if marked as coming soon
  return calculator.comingSoon;
};

// Helper to determine if a calculator should be shown in header navigation
export const shouldShowInHeader = (calculator) => {
  const isBeta = isInBetaEnvironment();
  
  if (isBeta) {
    // In beta: show everything
    return true;
  } else {
    // In production: show if not coming soon, or if it's a beta feature
    return !calculator.comingSoon || calculator.beta;
  }
};

// Helper to determine if a calculator should be accessible
export const isCalculatorAccessible = (calculator) => {
  const isBeta = isInBetaEnvironment();
  
  if (isBeta) {
    // In beta: everything is accessible
    return true;
  } else {
    // In production: accessible if not coming soon, or if it's a beta feature
    return !calculator.comingSoon || calculator.beta;
  }
};

// Helper to get the display name for the header menu
export const getCalculatorHeaderName = (path) => {
  const calculator = Object.values(calculatorConfig).find(calc => {
    return calc.path === path || 
           path?.startsWith(calc.path) ||
           path?.includes(calc.path.slice(1));
  });
  
  if (calculator) {
    // Add (BETA) suffix for beta features
    return calculator.beta ? `${calculator.headerMenu} (BETA)` : calculator.headerMenu;
  }
  return 'Calculator';
};

// Export calculator configurations
export const calculatorConfig = {
  aspectRatio: {
    production: true,
    beta: false,
    comingSoon: false,
    path: '/aspect-ratio',
    component: 'AspectRatioCalculator',
    title: 'Aspect Ratio Calculator',
    metaTitle: 'Free Aspect Ratio Calculator | Video Resolution Calculator',
    metaDescription: 'Calculate video aspect ratios, pixel dimensions, and resolutions. Free online tool for film, TV, and social media formats. Convert any video resolution instantly.',
    headerMenu: 'Aspect Ratio',
    sideMenu: 'Aspect Ratio',
    description: "Free online aspect ratio calculator. Convert video resolutions, calculate pixel dimensions, and find common aspect ratios for film, TV, and social media formats.",
    icon: <BsFillAspectRatioFill />,
    secondaryIcon: <FaCalculator />,
  },
  bitrate: {
    production: true,
    beta: true,
    comingSoon: false,
    path: '/bitrate',
    component: 'BitrateCalculator',
    title: 'Bitrate ↔︎ File Size Calculator',
    metaTitle: 'Video Bitrate Calculator | File Size & Quality Calculator',
    metaDescription: 'Professional video bitrate calculator for optimal streaming quality. Calculate video file sizes, compression settings, and streaming bitrates for any format.',
    headerMenu: 'Bitrate ↔︎ File Size',
    sideMenu: 'Bitrate ↔︎ File Size',
    description: "Professional video bitrate calculator. Calculate optimal bitrate settings, estimate file sizes, and determine video quality for streaming and encoding.",
    icon: <MdInsertChart />,
    secondaryIcon: <FaCalculator />,
  },
  camera: {
    production: true,
    beta: false,
    comingSoon: false,
    path: '/camera',
    component: 'CameraToFileSize',
    title: 'Camera ↔︎ File Size Calculator',
    metaTitle: 'Camera Storage Calculator | Video Recording Time Calculator',
    metaDescription: 'Calculate video storage requirements for ARRI, RED, Blackmagic, Sony & Canon cameras. Estimate recording time and file sizes for professional video production.',
    headerMenu: 'Camera ↔︎ File Size',
    sideMenu: 'Camera ↔︎ File Size',
    description: "Professional camera storage calculator. Estimate video file sizes and recording time for popular cinema cameras including ARRI, RED, Blackmagic, Sony, Canon, and more!",
    icon: <FaVideo />,
    secondaryIcon: <FaCalculator />,
  },
  dailies: {
    production: true,
    beta: true,
    comingSoon: false,
    path: '/dailies',
    component: 'DailiesCalculator',
    title: 'Dailies Calculator',
    metaTitle: 'Film Dailies Calculator | Production Storage Calculator',
    metaDescription: 'Calculate storage needs for film dailies workflow. Estimate transcode times, data management requirements, and storage capacity for professional film productions.',
    headerMenu: 'Dailies Calc',
    sideMenu: 'Dailies Calculator',
    description: "Film and video dailies calculator for professional productions. Calculate storage requirements and data management needs for your project's ideal workflow.",
    icon: <FaPlayCircle />,
    secondaryIcon: <FaCalculator />,
  },
  duration: {
    production: true,
    beta: false,
    comingSoon: false,
    path: '/duration',
    component: 'DurationToFileSize',
    title: 'Video Duration ↔︎ File Size Calculator',
    metaTitle: 'Video Duration & File Size Calculator | Storage Estimator',
    metaDescription: 'Convert between video duration and file size. Calculate storage requirements for any video format. Plan media storage needs with our professional calculator.',
    headerMenu: 'Duration ↔︎ File Size',
    sideMenu: 'Duration ↔︎ File Size',
    description: "Video duration and file size calculator. Quickly estimate storage needs, convert between duration and file size, and plan media storage for any video format.",
    icon: <FaClock />,
    secondaryIcon: <FaCalculator />,
  },
  fileName: {
    production: false,
    beta: false,
    comingSoon: true,
    path: '/filename-creator',
    component: 'FileNameCreator',
    title: 'File Name Creator',
    metaTitle: 'Professional File Name Generator | Media Naming Tool',
    metaDescription: 'Create standardized file names for video production and post-production. Generate consistent naming conventions for dailies, deliverables, and media assets.',
    headerMenu: 'File Name Creator',
    sideMenu: 'File Name Creator',
    description: "Professional file naming tool for media workflows. Create consistent, industry-standard file names for video, dailies, and post-production deliverables.",
    icon: <RiFileEditFill />,
    secondaryIcon: <HiSparkles />,
  },
  film: {
    production: true,
    beta: false,
    comingSoon: false,
    path: '/film',
    component: 'FilmCalculator',
    title: 'Motion Picture Film Calculator',
    metaTitle: '16mm & 35mm Film Calculator | Film Stock Calculator',
    metaDescription: 'Calculate film stock requirements for 16mm and 35mm productions. Essential calculator for cinematographers to determine footage needs and runtime.',
    headerMenu: 'Film Calc',
    sideMenu: 'Film Calculator',
    description: "Motion picture film stock calculator. Calculate 16mm and 35mm film footage, runtime, and stock needed. Essential tool for film productions and cinematographers.",
    icon: <PiFilmReelFill />,
    secondaryIcon: <FaCalculator />,
  },  
  framingChartGenerator: {
    production: true,
    beta: true,
    comingSoon: false,
    path: '/framing-chart',
    component: 'FramingChartGenerator',
    title: 'Framing Chart Generator',
    metaTitle: 'Professional Framing Chart Generator | Camera Guides',
    metaDescription: 'Create precise camera framing charts for film and video production. Generate safe areas, aspect ratio overlays, and composition guides for any format.',
    headerMenu: 'Framing Chart',
    sideMenu: 'Framing Chart',
    description: "Professional framing chart generator for film and video production. Create precise camera framing guides, safe areas, and aspect ratio overlays for any format.",
    icon: <AiFillPicture />,
    secondaryIcon: <HiSparkles />,
  },
  timecode: {
    production: true,
    beta: true,
    comingSoon: false,
    path: '/timecode',
    component: 'TimecodeCalculator',
    title: 'Timecode Calculator',
    metaTitle: 'Professional Timecode Calculator | Frame Rate Converter',
    metaDescription: 'Calculate and convert timecode for any frame rate. Add, subtract, and convert between different timecode formats. Essential tool for video professionals.',
    headerMenu: 'Timecode Calc',
    sideMenu: 'Timecode Calculator',
    description: "Professional timecode calculator for film and video. Convert between timecode formats, add/subtract timecode. An essential tool for video professionals",
    icon: <BsFillStopwatchFill />,
    secondaryIcon: <FaCalculator />,
  },
  transferTime: {
    production: true,
    beta: true,
    comingSoon: false,
    path: '/transfer-time',
    component: 'TransferTimeCalculator',
    title: 'Transfer Time Calculator',
    metaTitle: 'Media Transfer Time Calculator | File Transfer Estimator',
    metaDescription: 'Calculate video file transfer times across different storage devices and network speeds. Estimate data transfer duration for professional media workflows.',
    headerMenu: 'Transfer Time',
    sideMenu: 'Transfer Time',
    description: "Media transfer time calculator for video professionals. Estimate file transfer times across different storage devices, connections, and network speeds.",
    icon: <BiTransfer />,
    secondaryIcon: <BsFillStopwatchFill />,
  },
  logs: {
    production: true,
    beta: false,
    comingSoon: false,
    path: '/logs',
    component: 'Log',
    title: 'Logs',
    metaTitle: 'Video Calculator Logs | Save & Share Calculations',
    metaDescription: 'Track and share your video calculations. Save file sizes, durations, and technical specifications. Collaborate on media projects with shareable results.',
    headerMenu: 'Logs',
    sideMenu: 'Logs',
    description: "Save and share your video calculations. Track file sizes, durations, and technical specifications for your media projects.",
    icon: <FaHistory />,
    secondaryIcon: <FaClipboardList />,
  }
};

// Add this helper function to features.js
export const getCalculatorName = (path) => {
  const calculator = Object.values(calculatorConfig).find(calc => {
    // Handle both exact matches and path variations
    return calc.path === path || 
           path?.startsWith(calc.path) ||
           path?.includes(calc.path.slice(1)); // handle paths without leading slash
  });
  return calculator ? calculator.title : 'Calculator';
};

// Add this export
export const getBasename = () => {
  // Always return '/' since we're using subdomains instead of paths
  return '/';
};

// Add this helper for route paths
export const getRoutePath = (path) => {
  // Since we're using subdomains, just return the path as-is
  return path;
};

// Helper function to extract features from description
const extractFeatures = (description) => {
  // Split description into sentences and clean them up
  return description
    .split('.')
    .map(sentence => sentence.trim())
    .filter(sentence => sentence.length > 0)
    .map(sentence => {
      // Remove common starter words to make features more concise
      return sentence.replace(/^(Calculate|Estimate|Convert|Create|Generate|Professional|Free online|Track|Save|View)/i, '').trim();
    });
};

// Helper function to get schema markup for a calculator
export const getCalculatorSchema = (calculatorKey) => {
  const calculator = calculatorConfig[calculatorKey];
  if (!calculator) return null;

  // Get the current URL
  const baseUrl = typeof window !== 'undefined' ? window.location.origin : 'https://data-calc.com';
  
  // Extract features from description
  const features = extractFeatures(calculator.description);
  
  // Base schema that's common for all calculators
  const baseSchema = {
    "@context": "https://schema.org",
    "@type": "WebApplication",
    "name": calculator.metaTitle.split('|')[0].trim(),
    "headline": calculator.title,
    "description": calculator.metaDescription,
    "url": `${baseUrl}${calculator.path}`,
    "applicationCategory": "Calculator",
    "operatingSystem": "Any",
    "offers": {
      "@type": "Offer",
      "price": "0",
      "priceCurrency": "USD"
    },
    "featureList": features,
    "author": {
      "@type": "Organization",
      "name": "Data-Calc",
      "url": baseUrl
    }
  };

  // Add specific properties based on calculator type
  switch (calculatorKey) {
    case 'camera':
      return {
        ...baseSchema,
        "keywords": "camera calculator, video storage, ARRI, RED, Blackmagic, Sony, Canon, recording time calculator",
        "applicationSubCategory": "Video Production",
        "audience": {
          "@type": "Audience",
          "audienceType": "Video Professionals"
        }
      };

    case 'timecode':
      return {
        ...baseSchema,
        "keywords": "timecode calculator, frame rate converter, video timecode, professional timecode",
        "applicationSubCategory": "Video Production",
        "audience": {
          "@type": "Audience",
          "audienceType": "Video Editors"
        }
      };

    case 'film':
      return {
        ...baseSchema,
        "keywords": "film calculator, 16mm film, 35mm film, film stock calculator, cinematography",
        "applicationSubCategory": "Film Production",
        "audience": {
          "@type": "Audience",
          "audienceType": "Cinematographers"
        }
      };

    case 'aspectRatio':
      return {
        ...baseSchema,
        "keywords": "aspect ratio calculator, video resolution, pixel dimensions, TV safe areas",
        "applicationSubCategory": "Video Production",
        "audience": {
          "@type": "Audience",
          "audienceType": "Content Creators"
        }
      };

    case 'duration':
      return {
        ...baseSchema,
        "keywords": "video duration calculator, file size calculator, storage calculator, media storage",
        "applicationSubCategory": "Video Production",
        "audience": {
          "@type": "Audience",
          "audienceType": "Video Professionals"
        }
      };

    case 'transferTime':
      return {
        ...baseSchema,
        "keywords": "transfer time calculator, file transfer, media transfer, data transfer speed",
        "applicationSubCategory": "Data Transfer",
        "audience": {
          "@type": "Audience",
          "audienceType": "Media Professionals"
        }
      };

    case 'dailies':
      return {
        ...baseSchema,
        "keywords": "dailies calculator, film dailies, video dailies, production storage",
        "applicationSubCategory": "Film Production",
        "audience": {
          "@type": "Audience",
          "audienceType": "Film Production Teams"
        }
      };

    case 'framingChartGenerator':
      return {
        ...baseSchema,
        "keywords": "framing chart generator, camera guides, safe areas, aspect ratio overlays",
        "applicationSubCategory": "Video Production",
        "audience": {
          "@type": "Audience",
          "audienceType": "Camera Operators"
        }
      };

    // Default schema for other calculators
    default:
      return baseSchema;
  }
};

// Helper function to generate script tag content for schema
export const getSchemaScriptContent = (calculatorKey) => {
  const schema = getCalculatorSchema(calculatorKey);
  if (!schema) return '';
  return `<script type="application/ld+json">${JSON.stringify(schema, null, 2)}</script>`;
};

