import React, { useState, useEffect } from 'react';
import { SlDocs, SlLike, SlNote, SlPaperPlane, SlRefresh } from "react-icons/sl";
import { FaShareSquare, FaLock, FaUnlockAlt, FaAngleLeft, FaAngleRight, FaAngleUp } from 'react-icons/fa';
import { TbLetterCase, TbLetterCaseUpper, TbLetterCaseLower } from "react-icons/tb";
import { deliverableCodecs, codecAbbreviations } from '../data/codecData';
import { resolutions } from '../data/resolutionData';
import { netflixTemplates, netflixFields } from '../data/netflixData';
import { colors, spacing, commonStyles, layout } from '../styles/globalStyles';
import CalculatorButtons from './CalculatorButtons';
import { handleShareOnDevice } from '../utils/shareUtils';

// Move these constants to the top of the file, before the component definition
const availableTags = [
  'TITLE', 'TEXTED', 'COLORSPACE', 'GAMMA', 'CODEC', 'RESOLUTION', 'FRAMERATE', 'AUDIO',
  'DATE', 'TIME', 'INITIALS', 'JOB', 'BRAND', 'CAMPAIGN', 'LENGTH', 'VERSION',
  'DESCRIPTORS', 'ADID', 'RATIO'
];

const filenameTemplates = [
  {
    name: "Long Form Export",
    template: "[TITLE]_[TEXTED]_[COLORSPACE]_[GAMMA]_[CODEC]_[RESOLUTION]_[FRAMERATE]_[AUDIO]_[DATE]_[TIME]"
  },
  {
    name: "Short Form Deliverable",
    template: "[ADID]_[TITLE]_[LENGTH]_[RATIO]_[TEXTED]_[CODEC]_[RESOLUTION]_[FRAMERATE]_[AUDIO]_[DATE]"
  },
  {
    name: "Color Render",
    template: "[TITLE]_[COLORSPACE]_[GAMMA]_[CODEC]_[DATE]_[TIME]_[INITIALS]"
  },
  {
    name: "Short Form Audio Mix Final",
    template: "[JOB]_[BRAND]_[CAMPAIGN]_[LENGTH]_v[VERSION]_MASTER_[DESCRIPTORS]_[DATE]_[TIME]_[INITIALS]"
  },
  {
    name: "Custom",
    template: ""
  }
];

// Add this before the FileNameGenerator component definition
const sampleContent = {
  PROJECT_TITLE: 'Stranger Things',
  SEASON_EPISODE: 'Season 01 Episode 01',
  SEGMENT: 'Reel 1',
  ASSET_TYPE: 'Non-Graded Archival Master',
  BIT_DEPTH: '16 bit',
  DYNAMIC_RANGE: 'HDR',
  ACES_VERSION: 'ACES v1.1',
  COLOR_SPACE: 'P3D65',
  TRANSFER_FUNCTION: 'Linear',
  DATE: new Date().toISOString().slice(0, 10).replace(/-/g, ''),
  ASPECT_RATIO: '2.39:1',
  RESOLUTION: '3840x2160',
  FRAME: '000001',
  EXT: 'exr'
};

const FileNameGenerator = ({ addLog }) => {
  // Initialize with the first template from filenameTemplates instead of netflixTemplates
  const [selectedTemplate, setSelectedTemplate] = useState(filenameTemplates[0]);
  
  // Initialize formData with sampleContent
  const [formData, setFormData] = useState(sampleContent);
  const [copyButtonText, setCopyButtonText] = useState(<SlDocs />);
  const [logButtonText, setLogButtonText] = useState(<SlNote />);
  const [shareButtonText, setShareButtonText] = useState(<SlPaperPlane />);
  const [resetButtonText, setResetButtonText] = useState(<SlRefresh />);
  const [isMobile, setIsMobile] = useState(false);

  // Effect to detect mobile devices
  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));
    };
    checkMobile();
    window.addEventListener('resize', checkMobile);
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  const currentSpacing = isMobile ? spacing.mobile : spacing.desktop;

  // Use global styles
  const commonInputClass = commonStyles.commonInputClass;
  const textCenterImportant = commonStyles.textCenterImportant;
  const getInputStyle = (field) => commonStyles.getInputStyle(field, calculationMode);

  // Add missing handler functions
  const handleTemplateChange = (e) => {
    const selected = filenameTemplates.find(template => template.name === e.target.value);
    setSelectedTemplate(selected || filenameTemplates[0]);
  };

  const handleCopy = () => {
    const filename = generateFileName();
    navigator.clipboard.writeText(filename);
    setCopyButtonText(<SlLike />);
    setTimeout(() => setCopyButtonText(<SlDocs />), 500);
  };

  const handleShare = async () => {
    const shareUrl = new URL(window.location.href);
    // Add all form data to URL
    Object.entries(formData).forEach(([key, value]) => {
      shareUrl.searchParams.set(key, value);
    });
    shareUrl.searchParams.set('template', selectedTemplate.name);

    const shareButtonText = `${generateFileName()}\n` +
                     `Template: ${selectedTemplate.name}\n` +
                     `Path: ${generateFolderPath()}`;
    
    try {
      const result = await handleShareOnDevice(
        'TBD Post DataCalc',
        `Check out this filename format: ${shareButtonText}`,
        shareUrl.toString()
      );

      if (result?.success) {
        setShareButtonText(<SlLike />);
        setTimeout(() => setShareButtonText(<SlPaperPlane />), 500);
      }
      return result;
    } catch (error) {
      console.error('Share failed:', error);
      return { success: false, method: 'none' };
    }
  };

  const handleReset = () => {
    setFormData(sampleContent);
    setResetButtonText(<SlLike />);
    setTimeout(() => setResetButtonText(<SlRefresh />), 500);
  };

  const handleLogClick = () => {
    if (addLog) {
      addLog({
        type: 'filename',
        content: generateFileName(),
        timestamp: new Date().toISOString()
      });
    }
    setLogButtonText(<SlLike />);
    setTimeout(() => setLogButtonText(<SlNote />), 500);
  };

  const generateFileName = () => {
    // Implement filename generation based on selectedTemplate and formData
    return selectedTemplate.template.replace(/\[(\w+)\]/g, (match, field) => {
      return formData[field] || match;
    });
  };

  const generateFolderPath = () => {
    // Implement folder path generation
    return `/${formData.PROJECT_TITLE}/${formData.SEASON_EPISODE}/${formData.SEGMENT}`;
  };

  const renderFields = () => {
    const templateFields = selectedTemplate.template
      .match(/\[(\w+)\]/g)
      ?.map(field => field.replace(/[\[\]]/g, '')) || [];

    return (
      <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-4">
        {templateFields.map(fieldName => (
          <div key={fieldName}>
            <label className={commonStyles.labelClass}>{fieldName}</label>
            <input
              type="text"
              value={formData[fieldName] || ''}
              onChange={(e) => setFormData({...formData, [fieldName]: e.target.value})}
              className={commonInputClass}
            />
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className={`filename-generator ${currentSpacing.padding} ${colors.bgColor} ${colors.textColor}`}>
      <div className={layout.flex.column}>
        {/* Template Selector */}
        <div className={currentSpacing.fieldGap}>
          <label className={`${commonStyles.labelClass}`}>Template</label>
          <select
            value={selectedTemplate.name}
            onChange={handleTemplateChange}
            className={`${commonInputClass}`}
          >
            {filenameTemplates.map(template => (
              <option key={template.name} value={template.name}>
                {template.name}
              </option>
            ))}
          </select>
        </div>

        {/* Form Fields */}
        {renderFields()}

        {/* Preview Section */}
        <div className={`mt-6 p-4 ${colors.inputBgColor} rounded ${currentSpacing.fieldGap}`}>
          {/* Folder Path */}
          <div className={`${layout.flex.standard} space-x-2 mb-2`}>
            <svg className="w-5 h-5 text-gray-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 7v10a2 2 0 002 2h14a2 2 0 002-2V9a2 2 0 00-2-2h-6l-2-2H5a2 2 0 00-2 2z" />
            </svg>
            <span className="text-sm font-mono text-gray-600">
              {generateFolderPath()}
            </span>
          </div>
          
          {/* Filename */}
          <div className={`${layout.flex.standard} space-x-2`}>
            <svg className="w-5 h-5 text-gray-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
            </svg>
            <span className="text-sm font-mono break-all">
              {generateFileName()}
            </span>
          </div>
        </div>

        {/* Action Buttons */}
        <CalculatorButtons 
          onLog={handleLogClick}
          onCopy={handleCopy}
          onShare={handleShare}
          onReset={handleReset}
          copyText={<span className="font-bold">{generateFileName()}</span>}
          logButtonIcon={logButtonText}
          copyButtonIcon={copyButtonText}
          shareButtonIcon={shareButtonText}
          resetButtonIcon={resetButtonText}
        />
      </div>
    </div>
  );
};

export default FileNameGenerator; 