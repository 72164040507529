import React, { useState, useEffect, useCallback, useRef } from 'react';
import { SlDocs, SlNote, SlRefresh, SlLike, SlPaperPlane } from "react-icons/sl";
import { FaShareSquare, FaLock, FaUnlockAlt, FaAngleLeft, FaAngleRight, FaAngleUp } from 'react-icons/fa';
import { useCalculator } from '../context/CalculatorContext';
import { colors, spacing, commonStyles, layout } from '../styles/globalStyles';
import CalculatorButtons from './CalculatorButtons';
import { handleShareOnDevice } from '../utils/shareUtils';
import { filmFormats, framesPerFoot } from '../data/filmData';

// Define default framerates
const defaultFramerates = {
  timecode: ["23.976", "24", "25", "29.97", "30", "47.952", "48", "50", "59.94", "60"],
  footage: ["16", "24", "25", "30", "48", "50", "60"]
};

// Timecode class for handling timecode operations
class Timecode {
  constructor(hours = 0, minutes = 0, seconds = 0, frames = 0, frameRate = 24) {
    this.hours = Math.floor(hours);
    this.minutes = Math.floor(minutes);
    this.seconds = Math.floor(seconds);
    this.frames = Math.floor(frames);
    this.frameRate = frameRate;
  }

  // Parse a timecode string into a Timecode object
  static parse(timecodeString, frameRate = 24) {
    const parts = timecodeString.split(/[:.]/).map(Number);
    while (parts.length < 4) parts.unshift(0);
    const [hours, minutes, seconds, frames] = parts;
    return new Timecode(hours, minutes, seconds, frames, frameRate);
  }
  
  // Convert Timecode object to string
  toString() {
    return `${this.hours.toString().padStart(2, '0')}:${this.minutes.toString().padStart(2, '0')}:${this.seconds.toString().padStart(2, '0')}:${this.frames.toString().padStart(2, '0')}`;
  }

  // Calculate total frames
  totalFrames() {
    return this.hours * 3600 * this.frameRate + 
           this.minutes * 60 * this.frameRate + 
           this.seconds * this.frameRate + 
           this.frames;
  }

  // Create a Timecode object from total frames
  static fromFrames(totalFrames, frameRate = 24) {
    const hours = Math.floor(totalFrames / (3600 * frameRate));
    totalFrames %= 3600 * frameRate;
    const minutes = Math.floor(totalFrames / (60 * frameRate));
    totalFrames %= 60 * frameRate;
    const seconds = Math.floor(totalFrames / frameRate);
    const frames = Math.floor(totalFrames % frameRate);

    return new Timecode(hours, minutes, seconds, frames, frameRate);
  }

  // Convert timecode to keycode format (KK+FFFF)
  toKeycode() {
    const totalFrames = this.totalFrames();
    const key = Math.floor(totalFrames / 16);
    const frame = totalFrames % 16;
    return `${key.toString().padStart(2, '0')}+${frame.toString().padStart(4, '0')}`;
  }

  // Convert timecode to feet+frames format (FFF+FF)
  toFootage(format = '35mm 4-perf') {
    const framesPerFt = framesPerFoot[format];
    if (!framesPerFt) {
      console.warn(`Unknown film format: ${format}, defaulting to 35mm 4-perf`);
      return this.toFootage('35mm 4-perf');
    }
    const totalFrames = this.totalFrames();
    const feet = Math.floor(totalFrames / framesPerFt);
    const frame = Math.floor(totalFrames % framesPerFt);
    return `${feet.toString().padStart(3, '0')}+${frame.toString().padStart(2, '0')}`;
  }

  // Parse keycode format (KK+FFFF) to Timecode
  static fromKeycode(keycodeString, frameRate = 24) {
    const [key, frame] = keycodeString.split('+').map(Number);
    const totalFrames = key * 16 + frame;
    return Timecode.fromFrames(totalFrames, frameRate);
  }

  // Parse footage format (FFF+FF) to Timecode
  static fromFootage(footageString, frameRate = 24, format = '35mm 4-perf') {
    const framesPerFt = framesPerFoot[format];
    if (!framesPerFt) {
      console.warn(`Unknown film format: ${format}, defaulting to 35mm 4-perf`);
      return Timecode.fromFootage(footageString, frameRate, '35mm 4-perf');
    }
    const [feet, frame] = footageString.split('+').map(Number);
    const totalFrames = Math.floor(feet * framesPerFt + frame);
    return Timecode.fromFrames(totalFrames, frameRate);
  }

  // Basic arithmetic operations
  add(other) {
    const totalFrames = this.totalFrames() + other.totalFrames();
    return Timecode.fromFrames(totalFrames, this.frameRate);
  }

  subtract(other) {
    const totalFrames = Math.max(0, this.totalFrames() - other.totalFrames());
    return Timecode.fromFrames(totalFrames, this.frameRate);
  }

  multiply(factor) {
    const totalFrames = Math.floor(this.totalFrames() * factor);
    return Timecode.fromFrames(totalFrames, this.frameRate);
  }

  divide(factor) {
    if (factor === 0) return this;
    const totalFrames = Math.floor(this.totalFrames() / factor);
    return Timecode.fromFrames(totalFrames, this.frameRate);
  }
}

const TimecodeGenerator = ({ addLog }) => {
  const { timecodeCalcState, setTimecodeCalcState } = useCalculator();

  // State initialization
  const [mode, setMode] = useState(timecodeCalcState.mode || 'timecode');
  const [frameRate, setFrameRate] = useState(timecodeCalcState.frameRate || '24');
  const [timecode1, setTimecode1] = useState(timecodeCalcState.timecode1 || '00:00:00:00');
  const [timecode2, setTimecode2] = useState(timecodeCalcState.timecode2 || '00:00:00:00');
  const [operation, setOperation] = useState(timecodeCalcState.operation || 'add');
  const [result, setResult] = useState(timecodeCalcState.result || '00:00:00:00');
  const [isMobile, setIsMobile] = useState(timecodeCalcState.isMobile || false);
  const [filmFormat, setFilmFormat] = useState(timecodeCalcState.filmFormat || '35mm 4-perf');
  const [isCustomFramerate, setIsCustomFramerate] = useState(false);
  const [customFramerates, setCustomFramerates] = useState([]);
  const [logButtonText, setLogButtonText] = useState(<SlNote />);
  const [shareButtonText, setShareButtonText] = useState(<SlPaperPlane />);
  const [resetButtonText, setResetButtonText] = useState(<SlRefresh />);
  const [focusedField, setFocusedField] = useState(null);

  // NEW: Create refs for the two input fields
  const timecode1Ref = useRef(null);
  const timecode2Ref = useRef(null);

  // Add this constant at the top of the component, after state declarations
  const HANDLE_LOG_CLICK = {
    title: `Timecode Calculator`,
    getDetails: () => {
      const operationSymbols = {
        add: '+',
        subtract: '-',
        multiply: '×',
        divide: '÷'
      };
      return [
        `Mode: ${mode.toUpperCase()}`,
        `Frame Rate: ${frameRate} fps`,
        `${timecode1} ${operationSymbols[operation]} ${timecode2}`,
        `Result: ${result}`,
        `Total Frames: ${getTotalFrames(result)}`
      ].join('\n');
    }
  };

  // Get available frame rates based on current mode
  const getAvailableFrameRates = () => {
    const rates = mode === 'timecode' ? defaultFramerates.timecode : defaultFramerates.footage;
    return [...rates, ...customFramerates].sort((a, b) => parseFloat(a) - parseFloat(b));
  };

  // Handle frame rate change
  const handleFramerateChange = (e) => {
    const { value } = e.target;
    if (value === 'Custom') {
      setIsCustomFramerate(true);
      setFrameRate('');
    } else {
      setIsCustomFramerate(false);
      setFrameRate(value);
    }
  };

  const handleCustomFramerateChange = (e) => {
    const value = e.target.value.replace(/[^0-9.]/g, '');
    if (value.split('.').length <= 2) {
      setFrameRate(value);
    }
  };

  const handleCustomFramerateBlur = () => {
    const parsedFramerate = parseFloat(frameRate);
    if (!isNaN(parsedFramerate) && parsedFramerate > 0) {
      const newFramerate = formatNumber(parsedFramerate);
      if (!defaultFramerates[mode].includes(newFramerate) && !customFramerates.includes(newFramerate)) {
        setCustomFramerates(prev => [...prev, newFramerate].sort((a, b) => parseFloat(a) - parseFloat(b)));
      }
      setFrameRate(newFramerate);
      setIsCustomFramerate(false);
    } else if (frameRate.trim() === '') {
      setFrameRate('24');
      setIsCustomFramerate(false);
    }
  };

  // Effect to detect mobile devices
  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));
    };
    checkMobile();
    window.addEventListener('resize', checkMobile);
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  // Effect to save state to context
  useEffect(() => {
    setTimecodeCalcState({
      mode,
      frameRate,
      timecode1,
      timecode2,
      operation,
      result,
      isMobile
    });
  }, [mode, frameRate, timecode1, timecode2, operation, result, isMobile, setTimecodeCalcState]);

  // Input validation patterns
  const getValidationPattern = () => {
    switch (mode) {
      case 'timecode':
        return /^[0-9:]*$/;
      case 'footage':
        return /^[0-9+]*$/;
      case 'frames':
        return /^[0-9]*$/;
      default:
        return /^[0-9:]*$/;
    }
  };

  // Placeholder text based on mode
  const getPlaceholder = () => {
    switch (mode) {
      case 'timecode':
        return 'HH:MM:SS:FF';
      case 'footage':
        return 'FFF+FF';
      case 'frames':
        return 'Total Frames';
      default:
        return 'HH:MM:SS:FF';
    }
  };

  // Parse input based on mode
  const parseInput = (input, fr, format = '35mm 4-perf') => {
    try {
      // Replace all periods with ':00' to ensure they become two zeros
      input = input.replace(/\./g, ':00');

      if (!input || input.trim() === '') {
        return new Timecode(0, 0, 0, 0, fr);
      }

      const cleanInput = input.trim().toLowerCase();
      const frameRate = parseFloat(fr);

      // Handle footage format first if in footage mode
      if (mode === 'footage') {
        // Handle feet+frames format
        const footageMatch = cleanInput.match(/^(\d+)(?:\+|f)(\d+)?$/i);
        if (footageMatch) {
          const feet = parseInt(footageMatch[1]) || 0;
          const frames = parseInt(footageMatch[2]) || 0;
          return Timecode.fromFootage(`${feet}+${frames}`, frameRate, format);
        }

        // Try to parse as pure number (assume feet)
        const feet = parseInt(cleanInput);
        if (!isNaN(feet)) {
          return Timecode.fromFootage(`${feet}+0`, frameRate, format);
        }
      }

      // Handle single number input - interpret as seconds by default
      if (/^\d+$/.test(cleanInput)) {
        if (mode === 'frames') {
          return Timecode.fromFrames(parseInt(cleanInput, 10), frameRate);
        }
        const seconds = parseInt(cleanInput, 10);
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const remainingSeconds = seconds % 60;
        
        return new Timecode(hours, minutes, remainingSeconds, 0, frameRate);
      }

      // Handle input with colons after replacing periods
      if (/^\d+([:]\d+)*$/.test(cleanInput)) {
        const parts = cleanInput.split(':').map(Number);
        while (parts.length < 4) parts.unshift(0);
        let [hours, minutes, seconds, frames] = parts;

        // Adjust for overflow
        if (frames >= frameRate) {
          seconds += Math.floor(frames / frameRate);
          frames %= frameRate;
        }
        if (seconds >= 60) {
          minutes += Math.floor(seconds / 60);
          seconds %= 60;
        }
        if (minutes >= 60) {
          hours += Math.floor(minutes / 60);
          minutes %= 60;
        }

        // Ensure at least 1 frame
        const totalFrames = Math.max(1, (hours * 3600 + minutes * 60 + seconds) * frameRate + frames);
        return Timecode.fromFrames(totalFrames, frameRate);
      }

      // Define regex patterns for different time units
      const patterns = {
        week: /(\d+)\s*(?:w|wk|wks|week|weeks)/i,
        day: /(\d+)\s*(?:d|dy|dys|day|days)/i,
        hour: /(\d+)\s*(?:h|hr|hrs|hour|hours)/i,
        minute: /(\d+)\s*(?:m|min|mins|minute|minutes)/i,
        second: /(\d+)\s*(?:s|sec|secs|second|seconds)/i,
        frame: /(\d+)\s*(?:f|fr|frm|frms|frame|frames)/i,
        foot: /(\d+)\s*(?:ft|foot|feet)/i
      };

      let totalFrames = 0;
      let foundMatch = false;

      // Process all matches in the input string
      Object.entries(patterns).forEach(([unit, pattern]) => {
        const matches = cleanInput.matchAll(new RegExp(pattern, 'gi'));
        for (const match of matches) {
          foundMatch = true;
          const value = parseInt(match[1]);
          switch (unit) {
            case 'week': totalFrames += value * 7 * 24 * 3600 * frameRate; break;
            case 'day': totalFrames += value * 24 * 3600 * frameRate; break;
            case 'hour': totalFrames += value * 3600 * frameRate; break;
            case 'minute': totalFrames += value * 60 * frameRate; break;
            case 'second': totalFrames += value * frameRate; break;
            case 'frame': totalFrames += value; break;
            case 'foot': 
              if (mode === 'footage') {
                const framesPerFt = framesPerFoot[format];
                totalFrames += value * framesPerFt;
              }
              break;
          }
        }
      });

      if (foundMatch) {
        const tc = Timecode.fromFrames(totalFrames, frameRate);
        return mode === 'footage' ? Timecode.fromFootage(tc.toFootage(format), frameRate, format) : tc;
      }

      // If no other patterns match, try parsing as seconds
      const seconds = parseFloat(cleanInput);
      if (!isNaN(seconds)) {
        return Timecode.fromFrames(Math.round(seconds * frameRate), frameRate);
      }

      // If all parsing attempts fail, return default
      return new Timecode(0, 0, 0, 0, frameRate);
    } catch (error) {
      console.error('Error parsing input:', error);
      return new Timecode(0, 0, 0, 0, frameRate);
    }
  };

  // Format output based on mode
  const formatOutput = (timecode) => {
    switch (mode) {
      case 'timecode':
        return timecode.toString();
      case 'footage':
        return timecode.toFootage(filmFormat);
      case 'frames':
        return timecode.totalFrames().toString();
      default:
        return timecode.toString();
    }
  };

  // Format number with minimum decimals
  const formatNumber = (num) => {
    if (Number.isInteger(num)) return num.toString();
    const fixed = num.toFixed(3);
    return parseFloat(fixed).toString(); // Removes trailing zeros
  };

  // Add this helper function after the formatOutput function
  const getTotalFrames = (value) => {
    try {
      const tc = parseInput(value, frameRate, filmFormat);
      const frames = tc.totalFrames();
      if (mode === 'frames' || mode === 'footage') {
        return Math.round(frames).toString();
      }
      return formatNumber(frames);
    } catch (error) {
      return "0";
    }
  };

  // Calculate result
  const calculate = useCallback(() => {
    try {
      // Always convert first input to frames, regardless of input format
      const tc1 = parseInput(timecode1, frameRate, filmFormat);
      const tc2 = parseInput(timecode2, frameRate, filmFormat);
      const frames1 = tc1.totalFrames();
      const frames2 = tc2.totalFrames();

      let resultFrames;
      if (operation === 'add') {
        resultFrames = frames1 + frames2;
      } else {
        // subtract
        resultFrames = Math.max(0, frames1 - frames2);
      }

      // Create new Timecode object from result frames
      const resultTC = Timecode.fromFrames(resultFrames, frameRate);
      
      // Format output based on current mode
      let formattedResult;
      switch (mode) {
        case 'timecode':
          formattedResult = resultTC.toString();
          break;
        case 'frames':
          formattedResult = resultFrames.toString();
          break;
        case 'footage':
          formattedResult = resultTC.toFootage(filmFormat);
          break;
        default:
          formattedResult = resultTC.toString();
      }
      
      setResult(formattedResult);
    } catch (error) {
      console.error('Error calculating result:', error);
      setResult(formatOutput(new Timecode(0, 0, 0, 0, parseFloat(frameRate))));
    }
  }, [timecode1, timecode2, operation, frameRate, mode, filmFormat]);

  // Effect to recalculate when inputs change
  useEffect(() => {
    calculate();
  }, [calculate]);

  // Handle input changes
  const handleInputChange = (e, setter) => {
    const { value } = e.target;
    setter(value);
  };

  // Update the handleModeChange function
  const handleModeChange = (newMode) => {
    // Store current frame rate
    const currentFrameRate = frameRate;
    
    // Parse both current values to get their frame counts
    const tc1 = parseInput(timecode1, frameRate, filmFormat);
    const tc2 = parseInput(timecode2, frameRate, filmFormat);
    const resultTC = tc1.add(tc2);
    
    setMode(newMode);
    
    // Format frame rate based on new mode
    setFrameRate(getFormattedFrameRate(currentFrameRate, newMode));
    
    // Convert both values based on new mode, maintaining duration for each
    switch (newMode) {
      case 'timecode':
        setTimecode1(tc1.toString());
        setTimecode2(tc2.toString());
        setResult(resultTC.toString());
        // Restore original frame rate if switching back to timecode
        setFrameRate(currentFrameRate);
        break;
      case 'frames':
        setTimecode1(Math.round(tc1.totalFrames()).toString());
        setTimecode2(Math.round(tc2.totalFrames()).toString());
        setResult(Math.round(resultTC.totalFrames()).toString());
        break;
      case 'footage':
        setTimecode1(tc1.toFootage(filmFormat));
        setTimecode2(tc2.toFootage(filmFormat));
        setResult(resultTC.toFootage(filmFormat));
        break;
    }
    
    // Set default film format for footage mode if needed
    if (newMode === 'footage' && filmFormat !== '35mm 4-perf') {
      setFilmFormat('35mm 4-perf');
    }
  };

  // Update the initial state useEffect
  useEffect(() => {
    if (!timecodeCalcState.timecode1) {
      const oneHour = new Timecode(1, 0, 0, 0, 24);
      const thirtyMinutes = new Timecode(0, 30, 0, 0, 24);
      const result = oneHour.add(thirtyMinutes);
      
      switch (mode) {
        case 'timecode':
          setTimecode1(oneHour.toString());
          setTimecode2(thirtyMinutes.toString());
          setResult(result.toString());
          break;
        case 'frames':
          setTimecode1(Math.round(oneHour.totalFrames()).toString());
          setTimecode2(Math.round(thirtyMinutes.totalFrames()).toString());
          setResult(Math.round(result.totalFrames()).toString());
          break;
        case 'footage':
          setTimecode1(oneHour.toFootage(filmFormat));
          setTimecode2(thirtyMinutes.toFootage(filmFormat));
          setResult(result.toFootage(filmFormat));
          break;
      }
      
      setFrameRate('24');
      setFilmFormat('35mm 4-perf');
    }
  }, []);

  // Update the handleReset function
  const handleReset = () => {
    const oneHour = new Timecode(1, 0, 0, 0, 24);
    const thirtyMinutes = new Timecode(0, 30, 0, 0, 24);
    const result = oneHour.add(thirtyMinutes);
    
    switch (mode) {
      case 'timecode':
        setTimecode1(oneHour.toString());
        setTimecode2(thirtyMinutes.toString());
        setResult(result.toString());
        break;
      case 'frames':
        setTimecode1(Math.round(oneHour.totalFrames()).toString());
        setTimecode2(Math.round(thirtyMinutes.totalFrames()).toString());
        setResult(Math.round(result.totalFrames()).toString());
        break;
      case 'footage':
        setTimecode1(oneHour.toFootage(filmFormat));
        setTimecode2(thirtyMinutes.toFootage(filmFormat));
        setResult(result.toFootage(filmFormat));
        break;
    }
    
    setOperation('add');
    setResetButtonText(<SlLike />);
    setTimeout(() => setResetButtonText(<SlRefresh />), 500);
  };

  // Update the handleLog function
  const handleLog = () => {
    const logEntry = HANDLE_LOG_CLICK.getDetails();
    addLog(logEntry);

    if (navigator.clipboard && window.isSecureContext) {
      navigator.clipboard.writeText(logEntry)
        .then(() => {
          setLogButtonText(<SlLike />);
          setTimeout(() => setLogButtonText(<SlNote />), 500);
        })
        .catch(() => {
          const textArea = document.createElement("textarea");
          textArea.value = logEntry;
          document.body.appendChild(textArea);
          textArea.select();
          try {
            document.execCommand('copy');
            setLogButtonText(<>
              <SlLike />
            </>);
          } catch (err) {
            console.error('Failed to copy: ', err);
          }
          document.body.removeChild(textArea);
          setTimeout(() => setLogButtonText(<SlNote />), 500);
        });
    } else {
      const textArea = document.createElement("textarea");
      textArea.value = logEntry;
      document.body.appendChild(textArea);
      textArea.select();
      try {
        document.execCommand('copy');
        setLogButtonText(<>
          <SlLike />
        </>);
      } catch (err) {
        console.error('Failed to copy: ', err);
      }
      document.body.removeChild(textArea);
      setTimeout(() => setLogButtonText(<SlNote />), 500);
    }
  };

  // Update the handleShare function
  const handleShare = async () => {
    try {
      const result = await handleShareOnDevice(
        HANDLE_LOG_CLICK.title,
        HANDLE_LOG_CLICK.getDetails(),
        window.location.href
      );

      if (result?.success) {
        setShareButtonText(<>
          <SlLike className="mr-2" /> Share
        </>);
        setTimeout(() => setShareButtonText(<>
          <SlPaperPlane className="mr-2" /> Share
        </>), 500);
      }
    } catch (error) {
      console.error('Share failed:', error);
    }
  };

  // Helper function to get properly capitalized mode name
  const getCapitalizedMode = () => {
    switch (mode) {
      case 'timecode':
        return 'Timecode';
      case 'footage':
        return 'Footage';
      case 'frames':
        return 'Frames';
      default:
        return mode.charAt(0).toUpperCase() + mode.slice(1);
    }
  };

  // Update the handleCopy function
  const handleCopy = () => {
    const copyText = HANDLE_LOG_CLICK.getDetails();
    navigator.clipboard.writeText(copyText);
    setCopyButtonText(<SlLike /> );
    setTimeout(() => setCopyButtonText(<SlDocs />), 500);
  };

  const currentSpacing = isMobile ? spacing.mobile : spacing.desktop;

  // Format input value based on mode
  const formatInputValue = (value) => {
    switch (mode) {
      case 'keycode':
        const [kk = '', ff = ''] = value.split('+');
        return `${kk.padStart(2, '0')}+${ff.padStart(4, '0')}`;
      case 'footage':
        const [feet = '', frames = ''] = value.split('+');
        return `${feet.padStart(3, '0')}+${frames.padStart(2, '0')}`;
      default:
        const parts = value.split(':').map(part => part.padStart(2, '0'));
        while (parts.length < 4) parts.unshift('00');
        return parts.join(':');
    }
  };

  // Update the input fields to handle enter/return key
  const handleKeyDown = (e, setter) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      e.target.blur();
    }
  };

  // Update handleInputBlur to properly handle the second input for multiply/divide
  const handleInputBlur = (e, setter) => {
    const { value } = e.target;
    if (!value.trim()) {
      setter(getPlaceholder());
      return;
    }

    try {
      // For multiply/divide operations on second input, keep as scalar
      if (setter === setTimecode2 && (operation === 'multiply' || operation === 'divide')) {
        const numValue = parseFloat(value);
        if (!isNaN(numValue)) {
          setter(numValue.toString());
          return;
        }
      }

      // For all other cases, parse and format according to mode
      const tc = parseInput(value, frameRate, filmFormat);
      
      switch (mode) {
        case 'timecode':
          setter(tc.toString());
          break;
        case 'frames':
          setter(tc.totalFrames().toString());
          break;
        case 'footage':
          setter(tc.toFootage(filmFormat));
          break;
        default:
          setter(tc.toString());
      }
    } catch (error) {
      console.error('Error formatting input:', error);
      setter(getPlaceholder());
    }
  };

  // Add this new function near the other frame rate related functions
  const getFormattedFrameRate = (rate, currentMode) => {
    const parsedRate = parseFloat(rate);
    if (isNaN(parsedRate)) return '24';
    
    // For timecode mode, keep fractional frame rates
    if (currentMode === 'timecode') {
      return rate;
    }
    
    // For frames and footage modes, round to whole number
    return Math.round(parsedRate).toString();
  };

  // Handle wheel events for inputs
  const handleInputWheel = (e, value, setter) => {
    e.preventDefault();
    const rect = e.currentTarget.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const width = rect.width;
    const delta = Math.sign(-e.deltaY);

    if (mode === 'footage') {
      const [feet, frames] = value.split('+').map(Number);
      let newFeet = feet;
      let newFrames = frames;

      if (x < width / 3) {
        // Left third: 10x increment for feet
        newFeet = Math.max(0, feet + (delta * 10));
      } else if (x < (width * 2) / 3) {
        // Middle third: normal increment for feet
        newFeet = Math.max(0, feet + delta);
      } else {
        // Right third: frame increment
        newFrames = frames + delta;
        if (newFrames >= 16) {
          newFeet += Math.floor(newFrames / 16);
          newFrames %= 16;
        } else if (newFrames < 0 && newFeet > 0) {
          newFeet--;
          newFrames = 15;
        } else if (newFrames < 0) {
          newFrames = 0;
        }
      }
      setter(formatInputValue(`${newFeet}+${newFrames}`));
    } else if (mode === 'frames') {
      const frames = parseInt(value) || 0;
      const increment = x < width / 3 ? 100 : x < (width * 2) / 3 ? 10 : 1;
      setter(Math.max(0, frames + (delta * increment)).toString());
    } else {
      // New integrated wheel handling for timecode mode
      const sensitivity = 0.025;
      const scrollAmount = -e.deltaY * sensitivity; // Negative because scrolling direction is inverted
      const sgn = Math.sign(scrollAmount);
      let [hours, minutes, seconds, frames] = value.split(':').map(Number);
      const framerate = parseFloat(frameRate);
      
      if (x < width * 0.25) {
        // Scrolling hours
        hours += sgn;
        hours = Math.max(0, hours);
      } else if (x < width * 0.5) {
        // Scrolling minutes
        minutes += sgn;
        if (minutes < 0) {
          if (hours > 0) {
            hours--;
            minutes = 59;
          } else {
            minutes = 0;
          }
        } else if (minutes >= 60) {
          hours++;
          minutes = 0;
        }
      } else if (x < width * 0.75) {
        // Scrolling seconds
        seconds += sgn;
        if (seconds < 0) {
          if (minutes > 0) {
            minutes--;
            seconds += 60;
          } else if (hours > 0) {
            hours--;
            minutes = 59;
            seconds += 60;
          } else {
            seconds = 0;
          }
        }
        while (seconds >= 60) {
          minutes++;
          seconds -= 60;
          if (minutes >= 60) {
            hours++;
            minutes -= 60;
          }
        }
      } else {
        // Scrolling frames
        frames += sgn;
        if (frames < 0) {
          if (seconds > 0) {
            seconds--;
            frames += Math.floor(framerate);
          } else if (minutes > 0) {
            minutes--;
            seconds = 59;
            frames += Math.floor(framerate);
          } else if (hours > 0) {
            hours--;
            minutes = 59;
            seconds = 59;
            frames += Math.floor(framerate);
          } else {
            frames = 0;
          }
        } else if (frames >= Math.floor(framerate)) {
          seconds++;
          frames -= Math.floor(framerate);
          if (seconds >= 60) {
            minutes++;
            seconds -= 60;
            if (minutes >= 60) {
              hours++;
              minutes -= 60;
            }
          }
        }
      }
      
      const newTimecode = `${hours.toString().padStart(2, '0')}:` +
                          `${minutes.toString().padStart(2, '0')}:` +
                          `${seconds.toString().padStart(2, '0')}:` +
                          `${frames.toString().padStart(2, '0')}`;
      setter(newTimecode);
    }
  };

  // Handle touch events
  const [touchState, setTouchState] = useState({
    startY: 0,
    touchX: 0,
    fieldWidth: 0,
    startValue: null,
    field: null
  });

  const handleTouchMove = useCallback((e) => {
    e.preventDefault();
    if (!touchState.startValue) return;

    const touch = e.touches[0];
    const currentY = touch.clientY;
    const diffY = touchState.startY - currentY;
    const sensitivity = 0.5; // Increased sensitivity to match DurationToFileSize
    
    const segment = Math.floor(touchState.touchX / (touchState.fieldWidth / 4));
    const fr = parseFloat(frameRate);
    let change = Math.round(diffY * sensitivity);
    let { hours, minutes, seconds, frames } = touchState.startValue;
    let newValue;

    if (mode === 'frames') {
      // For frames mode, adjust total frames based on position
      const totalFrames = parseInt(touchState.startValue.frames);
      const increment = touchState.touchX < touchState.fieldWidth / 2 ? 100 : 1;
      const newFrames = Math.max(0, totalFrames - (change * increment));
      newValue = newFrames.toString();
    } else if (mode === 'footage') {
      // For footage mode, adjust feet or frames based on position
      const [feet, frms] = [hours, frames]; // Using hours for feet in footage mode
      const framesPerFt = framesPerFoot[filmFormat];
      
      if (touchState.touchX < touchState.fieldWidth / 2) {
        // Adjust feet with increased sensitivity for larger numbers
        const newFeet = Math.max(0, feet - change * (Math.abs(change) > 1 ? 10 : 1));
        newValue = `${newFeet.toString().padStart(3, '0')}+${frms.toString().padStart(2, '0')}`;
      } else {
        // Adjust frames
        let newFrames = frms - change;
        let newFeet = feet;
        
        while (newFrames < 0 && newFeet > 0) {
          newFeet--;
          newFrames += framesPerFt;
        }
        while (newFrames >= framesPerFt) {
          newFeet++;
          newFrames -= framesPerFt;
        }
        
        newFrames = Math.max(0, newFrames);
        newValue = `${newFeet.toString().padStart(3, '0')}+${newFrames.toString().padStart(2, '0')}`;
      }
    } else {
      // Timecode mode with improved segment handling
      const segmentSize = touchState.fieldWidth / 4;
      const segmentPosition = touchState.touchX % segmentSize;
      const segmentMultiplier = segmentPosition < segmentSize / 2 ? 10 : 1;
      
      switch(segment) {
        case 0: // Hours
          hours = Math.max(0, hours - (change * segmentMultiplier));
          break;
        case 1: // Minutes
          minutes -= change * segmentMultiplier;
          while (minutes < 0) {
            if (hours > 0) {
              hours--;
              minutes += 60;
            } else {
              minutes = 0;
            }
          }
          while (minutes >= 60) {
            hours++;
            minutes -= 60;
          }
          break;
        case 2: // Seconds
          seconds -= change * segmentMultiplier;
          while (seconds < 0) {
            if (minutes > 0) {
              minutes--;
              seconds += 60;
            } else if (hours > 0) {
              hours--;
              minutes = 59;
              seconds += 60;
            } else {
              seconds = 0;
            }
          }
          while (seconds >= 60) {
            minutes++;
            seconds -= 60;
            if (minutes >= 60) {
              hours++;
              minutes -= 60;
            }
          }
          break;
        case 3: // Frames
          frames -= change;
          while (frames < 0) {
            if (seconds > 0) {
              seconds--;
              frames += Math.floor(fr);
            } else if (minutes > 0) {
              minutes--;
              seconds = 59;
              frames += Math.floor(fr);
            } else if (hours > 0) {
              hours--;
              minutes = 59;
              seconds = 59;
              frames += Math.floor(fr);
            } else {
              frames = 0;
            }
          }
          while (frames >= fr) {
            seconds++;
            frames -= Math.floor(fr);
            if (seconds >= 60) {
              minutes++;
              seconds -= 60;
              if (minutes >= 60) {
                hours++;
                minutes -= 60;
              }
            }
          }
          break;
      }
      
      if (mode === 'timecode') {
        newValue = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}:${frames.toString().padStart(2, '0')}`;
      }
    }
    
    if (touchState.field === 'timecode1') {
      setTimecode1(newValue);
    } else if (touchState.field === 'timecode2') {
      setTimecode2(newValue);
    }
  }, [touchState, frameRate, mode, filmFormat]);

  const handleTouchStart = (e, field) => {
    e.preventDefault();
    const touch = e.touches[0];
    const rect = e.target.getBoundingClientRect();
    let startValue;

    if (mode === 'frames') {
      // For frames mode, store the total frames in the frames field
      startValue = {
        hours: 0,
        minutes: 0,
        seconds: 0,
        frames: parseInt(e.target.value) || 0
      };
    } else if (mode === 'footage') {
      // For footage mode, store feet in hours and frames in frames
      const [feet, frames] = e.target.value.split('+').map(num => parseInt(num) || 0);
      startValue = {
        hours: feet,
        minutes: 0,
        seconds: 0,
        frames: frames
      };
    } else {
      // Timecode mode
      const [hours, minutes, seconds, frames] = e.target.value.split(':').map(num => parseInt(num) || 0);
      startValue = { hours, minutes, seconds, frames };
    }

    setTouchState({
      startY: touch.clientY,
      touchX: touch.clientX - rect.left,
      fieldWidth: rect.width,
      startValue,
      field
    });

    const handleTouchEnd = () => {
      setTouchState(prev => ({ ...prev, startValue: null }));
      document.removeEventListener('touchmove', handleTouchMove);
      document.removeEventListener('touchend', handleTouchEnd);
    };

    document.addEventListener('touchmove', handleTouchMove, { passive: false });
    document.addEventListener('touchend', handleTouchEnd);
  };

  // First define the wheel handlers
  const handleFrameRateWheel = (e) => {
    e.preventDefault();
    const rates = getAvailableFrameRates();
    const currentIndex = rates.indexOf(frameRate);
    const delta = Math.sign(-e.deltaY);
    
    if (delta > 0) {
      // Scroll up, go to next frame rate
      const nextIndex = currentIndex < rates.length - 1 ? currentIndex + 1 : currentIndex;
      setFrameRate(rates[nextIndex]);
    } else {
      // Scroll down, go to previous frame rate
      const prevIndex = currentIndex > 0 ? currentIndex - 1 : currentIndex;
      setFrameRate(rates[prevIndex]);
    }
  };

  const handleOperationWheel = (e) => {
    e.preventDefault();
    const operations = ['add', 'subtract'];
    const currentIndex = operations.indexOf(operation);
    const delta = Math.sign(-e.deltaY);
    
    if (delta > 0) {
      // Scroll up, go to next operation
      const nextIndex = currentIndex < operations.length - 1 ? currentIndex + 1 : currentIndex;
      setOperation(operations[nextIndex]);
    } else {
      // Scroll down, go to previous operation
      const prevIndex = currentIndex > 0 ? currentIndex - 1 : currentIndex;
      setOperation(operations[prevIndex]);
    }
  };

  // Define the handlers before the JSX return
  const handleDropdownTouch = useCallback((e, options, currentValue, fieldName) => {
    if (!e.touches || !e.touches[0] || !options) return;
    
    const touch = e.touches[0];
    const startY = touch.clientY;
    let lastY = startY;
    let isSwiping = false;
    let touchTimeout;
    
    const scrollableOptions = Array.isArray(options) ? options.filter(option => option !== 'Custom') : [];
    let currentIndex = scrollableOptions.indexOf(currentValue);
    let accumulatedDelta = 0;
    
    touchTimeout = setTimeout(() => {
      if (!isSwiping) {
        e.target.click();
      }
    }, 100);
    
    const handleTouchMove = (e) => {
      if (!e.touches || !e.touches[0]) return;
      
      clearTimeout(touchTimeout);
      
      const touch = e.touches[0];
      const currentY = touch.clientY;
      const totalMove = Math.abs(currentY - startY);
      
      if (totalMove > 5) {
        e.preventDefault();
        isSwiping = true;
        
        const diffY = lastY - currentY;
        lastY = currentY;
        
        accumulatedDelta += diffY * 0.6;
        
        const itemsToMove = Math.floor(Math.abs(accumulatedDelta) / 10);
        
        if (itemsToMove >= 1) {
          const direction = Math.sign(accumulatedDelta);
          let newIndex = currentIndex + (direction * itemsToMove);
          newIndex = Math.max(0, Math.min(newIndex, scrollableOptions.length - 1));
          
          if (newIndex !== currentIndex) {
            const newValue = scrollableOptions[newIndex];
            switch (fieldName) {
              case 'mode':
                handleModeChange(newValue);
                break;
              case 'framerate':
                setFrameRate(newValue);
                break;
              case 'operation':
                setOperation(newValue);
                break;
              case 'filmFormat':
                setFilmFormat(newValue);
                break;
            }
            currentIndex = newIndex;
          }
          
          accumulatedDelta = accumulatedDelta % 10;
        }
      }
    };

    const handleTouchEnd = () => {
      clearTimeout(touchTimeout);
      document.removeEventListener('touchmove', handleTouchMove);
      document.removeEventListener('touchend', handleTouchEnd);
      
      if (!isSwiping) {
        e.target.click();
      }
    };

    document.addEventListener('touchmove', handleTouchMove, { passive: false });
    document.addEventListener('touchend', handleTouchEnd);
  }, [handleModeChange, setFrameRate, setOperation, setFilmFormat]);

  const handleModeTouchStart = useCallback((e) => {
    handleDropdownTouch(e, ['timecode', 'frames', 'footage'], mode, 'mode');
  }, [handleDropdownTouch, mode]);

  const handleFramerateTouchStart = useCallback((e) => {
    handleDropdownTouch(e, getAvailableFrameRates(), frameRate, 'framerate');
  }, [handleDropdownTouch, getAvailableFrameRates, frameRate]);

  const handleOperationTouchStart = useCallback((e) => {
    handleDropdownTouch(e, ['add', 'subtract'], operation, 'operation');
  }, [handleDropdownTouch, operation]);

  const handleFilmFormatTouchStart = useCallback((e) => {
    handleDropdownTouch(e, filmFormats, filmFormat, 'filmFormat');
  }, [handleDropdownTouch, filmFormat]);

  // Add these handler functions
  const handleFilmFormatWheel = (e) => {
    e.preventDefault();
    const formats = filmFormats;
    const currentIndex = formats.indexOf(filmFormat);
    const delta = Math.sign(-e.deltaY);
    
    if (delta > 0) {
      const nextIndex = currentIndex < formats.length - 1 ? currentIndex + 1 : currentIndex;
      setFilmFormat(formats[nextIndex]);
    } else {
      const prevIndex = currentIndex > 0 ? currentIndex - 1 : currentIndex;
      setFilmFormat(formats[prevIndex]);
    }
  };

  const handleModeWheel = (e) => {
    e.preventDefault();
    const modes = ['timecode', 'frames', 'footage']; // Updated order
    const currentIndex = modes.indexOf(mode);
    const delta = Math.sign(-e.deltaY);
    
    const nextIndex = (currentIndex + (delta > 0 ? 1 : -1) + modes.length) % modes.length;
    handleModeChange(modes[nextIndex]);
  };

  const handleModeTouchMove = (e) => {
    e.preventDefault();
    const touch = e.touches[0];
    const lastY = parseFloat(e.currentTarget.dataset.lastTouchY);
    const deltaY = lastY - touch.clientY;
    
    if (Math.abs(deltaY) > 10) {
      handleModeWheel({ preventDefault: () => {}, deltaY });
      e.currentTarget.dataset.lastTouchY = touch.clientY;
    }
  };

  const handleFilmFormatTouchMove = (e) => {
    e.preventDefault();
    const touch = e.touches[0];
    const lastY = parseFloat(e.currentTarget.dataset.lastTouchY);
    const deltaY = lastY - touch.clientY;
    
    if (Math.abs(deltaY) > 10) {
      handleFilmFormatWheel({ preventDefault: () => {}, deltaY });
      e.currentTarget.dataset.lastTouchY = touch.clientY;
    }
  };

  // Add these handler functions for footage operators
  const handleFootageWheel = (e, value, setter) => {
    e.preventDefault();
    const rect = e.currentTarget.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const width = rect.width;
    const delta = Math.sign(-e.deltaY);

    const [feet, frames] = value.split('+').map(Number);
    let newFeet = feet;
    let newFrames = frames;

    if (x < width * 0.5) {
      // Adjust feet
      newFeet = Math.max(0, feet + (delta * (e.shiftKey ? 10 : 1)));
    } else {
      // Adjust frames
      newFrames = frames + delta;
      if (newFrames >= 16) {
        newFeet += Math.floor(newFrames / 16);
        newFrames %= 16;
      } else if (newFrames < 0 && newFeet > 0) {
        newFeet--;
        newFrames = 15;
      } else if (newFrames < 0) {
        newFrames = 0;
      }
    }

    setter(formatInputValue(`${newFeet}+${newFrames}`));
  };

  const handleFootageTouchStart = (e, field) => {
    const touch = e.touches[0];
    const rect = e.currentTarget.getBoundingClientRect();
    const x = touch.clientX - rect.left;
    
    e.currentTarget.dataset.touchStartX = x;
    e.currentTarget.dataset.touchStartY = touch.clientY;
    e.currentTarget.dataset.lastTouchY = touch.clientY;
    e.currentTarget.dataset.touchField = field;
  };

  const handleFootageTouchMove = (e, value, setter) => {
    e.preventDefault();
    const touch = e.touches[0];
    const rect = e.currentTarget.getBoundingClientRect();
    const startX = parseFloat(e.currentTarget.dataset.touchStartX);
    const lastY = parseFloat(e.currentTarget.dataset.lastTouchY);
    const deltaY = lastY - touch.clientY;
    const width = rect.width;
    
    const [feet, frames] = value.split('+').map(Number);
    let newFeet = feet;
    let newFrames = frames;
    
    if (Math.abs(deltaY) > 2) { // More sensitive for touch
      if (startX < width * 0.5) {
        // Adjust feet with increased sensitivity for touch
        newFeet = Math.max(0, feet + Math.sign(-deltaY) * (Math.abs(deltaY) > 10 ? 10 : 1));
      } else {
        // Adjust frames
        newFrames = frames + Math.sign(-deltaY);
        if (newFrames >= 16) {
          newFeet += Math.floor(newFrames / 16);
          newFrames %= 16;
        } else if (newFrames < 0 && newFeet > 0) {
          newFeet--;
          newFrames = 15;
        } else if (newFrames < 0) {
          newFrames = 0;
        }
      }
      
      setter(formatInputValue(`${newFeet}+${newFrames}`));
      e.currentTarget.dataset.lastTouchY = touch.clientY;
    }
  };

  // NEW: New touch start handler for timecode fields that mimics DurationToFileSize.js
  const handleTouchStartForTimecode = useCallback((e, field) => {
    e.preventDefault();
    const touch = e.touches[0];
    const rect = e.target.getBoundingClientRect();
    const touchX = touch.clientX - rect.left;
    const fieldWidth = rect.width;
    const startY = touch.clientY;

    // Get current value based on mode
    const currentValue = e.target.value;
    let startValue;

    if (mode === 'frames') {
      startValue = parseInt(currentValue, 10) || 0;
    } else if (mode === 'footage') {
      const [feet, frames] = currentValue.split('+').map(num => parseInt(num, 10));
      startValue = { feet: feet || 0, frames: frames || 0 };
    } else { // timecode mode
      const parts = currentValue.split(':').map(num => parseInt(num, 10) || 0);
      while (parts.length < 4) parts.unshift(0);
      startValue = {
        hours: parts[0],
        minutes: parts[1],
        seconds: parts[2],
        frames: parts[3]
      };
    }

    const handleTouchMove = (e) => {
      e.preventDefault();
      const touchCurrent = e.touches[0];
      const diffY = startY - touchCurrent.clientY;
      const sensitivity = 0.1;
      const change = Math.round(diffY * sensitivity);

      let newValue;

      if (mode === 'frames') {
        // Four zones for frames mode: 1000x, 100x, 10x, 1x
        let multiplier;
        if (touchX < fieldWidth * 0.25) {
          multiplier = 1000; // Left zone: 1000x
        } else if (touchX < fieldWidth * 0.5) {
          multiplier = 100;  // Second zone: 100x
        } else if (touchX < fieldWidth * 0.75) {
          multiplier = 10;  // Third zone: 10x
        } else {
          multiplier = 1;   // Right zone: 1x
        }
        newValue = Math.max(0, startValue - (change * multiplier));
        newValue = newValue.toString();
      } else if (mode === 'footage') {
        const framesPerFt = framesPerFoot[filmFormat];
        let { feet, frames } = startValue;
        
        if (touchX < fieldWidth * 0.25) {
          // Left zone: 100 feet at a time
          feet = Math.max(0, feet - (change * 100));
        } else if (touchX < fieldWidth * 0.5) {
          // Second zone: 10 feet at a time
          feet = Math.max(0, feet - (change * 10));
        } else if (touchX < fieldWidth * 0.75) {
          // Third zone: 1 foot at a time
          feet = Math.max(0, feet - change);
        } else {
          // Right zone: frames
          frames -= change;
          while (frames < 0) {
            if (feet > 0) {
              feet--;
              frames += framesPerFt;
            } else {
              frames = 0;
              break;
            }
          }
          while (frames >= framesPerFt) {
            feet++;
            frames -= framesPerFt;
          }
        }
        newValue = `${feet.toString().padStart(3, '0')}+${frames.toString().padStart(2, '0')}`;
      } else { // timecode mode
        const segment = Math.floor(touchX / (fieldWidth / 4));
        const framerate = parseFloat(frameRate);
        let { hours, minutes, seconds, frames } = startValue;

        switch(segment) {
          case 0: // Hours
            hours = Math.max(0, hours - change);
            break;
          case 1: // Minutes
            minutes -= change;
            while (minutes < 0) {
              if (hours > 0) {
                hours--;
                minutes += 60;
              } else {
                minutes = 0;
              }
            }
            while (minutes >= 60) {
              hours++;
              minutes -= 60;
            }
            break;
          case 2: // Seconds
            seconds -= change;
            while (seconds < 0) {
              if (minutes > 0) {
                minutes--;
                seconds += 60;
              } else if (hours > 0) {
                hours--;
                minutes = 59;
                seconds += 60;
              } else {
                seconds = 0;
              }
            }
            while (seconds >= 60) {
              minutes++;
              seconds -= 60;
              if (minutes >= 60) {
                hours++;
                minutes -= 60;
              }
            }
            break;
          case 3: // Frames
            frames -= change;
            while (frames < 0) {
              if (seconds > 0) {
                seconds--;
                frames += Math.floor(framerate);
              } else if (minutes > 0) {
                minutes--;
                seconds = 59;
                frames += Math.floor(framerate);
              } else if (hours > 0) {
                hours--;
                minutes = 59;
                seconds = 59;
                frames += Math.floor(framerate);
              } else {
                frames = 0;
              }
            }
            while (frames >= framerate) {
              seconds++;
              frames -= Math.floor(framerate);
              if (seconds >= 60) {
                minutes++;
                seconds -= 60;
                if (minutes >= 60) {
                  hours++;
                  minutes -= 60;
                }
              }
            }
            break;
        }
        newValue = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}:${frames.toString().padStart(2, '0')}`;
      }

      // Update the appropriate field
      if (field === 'timecode1') {
        setTimecode1(newValue);
      } else if (field === 'timecode2') {
        setTimecode2(newValue);
      }
    };

    const handleTouchEnd = () => {
      document.removeEventListener('touchmove', handleTouchMove);
      document.removeEventListener('touchend', handleTouchEnd);
    };

    document.addEventListener('touchmove', handleTouchMove, { passive: false });
    document.addEventListener('touchend', handleTouchEnd);
  }, [mode, frameRate, filmFormat]);

  return (
    <div className={`calculator ${currentSpacing.padding} ${colors.bgColor} ${colors.textColor}`}>
      <div className={layout.flex.column}>
        {/* Main Calculator Layout */}
        <div>
          {/* Timecode Values and Operation Row */}
          <div className={`grid grid-cols-7 gap-2 ${currentSpacing.fieldGap}`}>
            <div className="col-span-3">
              <label className={`${commonStyles.labelClass} md:text-base`}>{getCapitalizedMode()}</label>
              <input
                ref={timecode1Ref}
                type="text"
                value={timecode1}
                onChange={(e) => handleInputChange(e, setTimecode1)}
                onBlur={(e) => handleInputBlur(e, setTimecode1)}
                onKeyDown={(e) => handleKeyDown(e, setTimecode1)}
                onFocus={(e) => {
                  setFocusedField('timecode1');
                  e.target.select();
                }}
                onWheel={(e) => handleInputWheel(e, timecode1, setTimecode1)}
                onTouchStart={(e) => handleTouchStartForTimecode(e, 'timecode1')}
                placeholder={getPlaceholder()}
                className={`${commonStyles.commonInputClass} text-sm md:text-base text-center`}
                style={{ touchAction: 'none' }}
              />
            </div>

            <div className="col-span-1">
              <label className={`${commonStyles.labelClass} md:text-base`}>Math</label>
              <select
                value={operation}
                onChange={(e) => setOperation(e.target.value)}
                onWheel={handleOperationWheel}
                onTouchStart={handleOperationTouchStart}
                className={`${commonStyles.commonInputClass} text-sm md:text-base text-center`}
              >
                <option value="add">+</option>
                <option value="subtract">−</option>
              </select>
            </div>

            <div className="col-span-3">
              <label className={`${commonStyles.labelClass} md:text-base`}>{getCapitalizedMode()}</label>
              <input
                ref={timecode2Ref}
                type="text"
                value={timecode2}
                onChange={(e) => handleInputChange(e, setTimecode2)}
                onBlur={(e) => handleInputBlur(e, setTimecode2)}
                onKeyDown={(e) => handleKeyDown(e, setTimecode2)}
                onFocus={(e) => {
                  setFocusedField('timecode2');
                  e.target.select();
                }}
                onWheel={(e) => handleInputWheel(e, timecode2, setTimecode2)}
                onTouchStart={(e) => handleTouchStartForTimecode(e, 'timecode2')}
                placeholder={getPlaceholder()}
                className={`${commonStyles.commonInputClass} text-sm md:text-base text-center`}
                style={{ touchAction: 'none' }}
              />
            </div>
          </div>

          {/* Mode and Frame Rate Row */}
          <div className={`grid grid-cols-12 gap-2 ${currentSpacing.fieldGap}`}>
            <div className={mode === 'footage' ? "col-span-4" : "col-span-6"}>
              <label className={`${commonStyles.labelClass} md:text-base`}>Mode</label>
              <select
                value={mode}
                onChange={(e) => handleModeChange(e.target.value)}
                onWheel={handleModeWheel}
                onTouchStart={handleModeTouchStart}
                className={`${commonStyles.commonInputClass} text-sm md:text-base text-center`}
              >
                <option value="timecode">Timecode</option>
                <option value="frames">Frames</option>
                <option value="footage">Feet+Frames</option>
              </select>
            </div>
            {mode === 'footage' && (
              <div className="col-span-4">
                <label className={`${commonStyles.labelClass} md:text-base`}>Film Format</label>
                <select
                  value={filmFormat}
                  onChange={(e) => setFilmFormat(e.target.value)}
                  onWheel={handleFilmFormatWheel}
                  onTouchStart={handleFilmFormatTouchStart}
                  className={`${commonStyles.commonInputClass} text-sm md:text-base text-center`}
                >
                  {filmFormats.map(format => (
                    <option key={format} value={format}>{format}</option>
                  ))}
                </select>
              </div>
            )}
            <div className={mode === 'footage' ? "col-span-4" : "col-span-6"}>
              <label className={`${commonStyles.labelClass} md:text-base`}>Frame Rate</label>
              {isCustomFramerate ? (
                <input
                  type="text"
                  value={frameRate}
                  onChange={handleCustomFramerateChange}
                  onBlur={handleCustomFramerateBlur}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      e.preventDefault();
                      e.target.blur();
                    }
                  }}
                  placeholder="Enter frame rate"
                  className={`${commonStyles.commonInputClass} text-sm md:text-base text-center`}
                />
              ) : (
                <select
                  value={frameRate}
                  onChange={handleFramerateChange}
                  onWheel={handleFrameRateWheel}
                  onTouchStart={handleFramerateTouchStart}
                  className={`${commonStyles.commonInputClass} text-sm md:text-base text-center`}
                >
                  {getAvailableFrameRates().map(rate => (
                    <option key={rate} value={rate}>{rate} fps</option>
                  ))}
                  <option value="Custom">Custom</option>
                </select>
              )}
            </div>
          </div>

          {/* Total Results Row */}
          <div className={`grid grid-cols-12 gap-2 ${currentSpacing.fieldGap}`}>
            <div className="col-span-12">
              <label className={`${commonStyles.labelClass} md:text-base`}>Total Duration</label>
              <div className={`${commonStyles.commonInputClass} text-sm md:text-base text-center flex items-center justify-center`}>
                <span>{mode === 'timecode' ? result : parseInput(result, frameRate, filmFormat).toString()}</span>
                <span className="mx-2 text-gray-300">|</span>
                <span>{getTotalFrames(result)} frames</span>
                <span className="mx-2 text-gray-300">|</span>
                <span>{mode === 'footage' ? result : parseInput(result, frameRate, filmFormat).toFootage(filmFormat)}</span>
              </div>
            </div>
          </div>
        </div>

        {/* Calculator Buttons */}
        <CalculatorButtons
          onLog={handleLog}
          // onCopy={handleCopy}
          onShare={handleShare}
          onReset={handleReset}
          logButtonIcon={logButtonText}
          shareButtonIcon={shareButtonText}
          resetButtonIcon={resetButtonText}
          isMobile={isMobile}
        />
      </div>
    </div>
  );
};

export default TimecodeGenerator; 