import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { 
  calculatorConfig, 
  isCalculatorAccessible, 
  shouldShowBetaPill,
  shouldShowComingSoonPill 
} from '../config/features';

const SideMenu = ({ isMobile }) => {
  const location = useLocation();

  const renderMenuItem = (calc) => {
    const isActive = location.pathname === calc.path;
    const isAccessible = isCalculatorAccessible(calc);
    const baseClasses = `flex items-center justify-between px-2 py-2 rounded-md transition-colors`;
    
    // For hidden items
    if (calc.hidden) {
      return null;
    }

    const iconGroup = (
      <div className="flex items-center gap-1">
        {calc.icon}
        {calc.secondaryIcon}
      </div>
    );

    // If not accessible (coming soon in production), render as disabled
    if (!isAccessible) {
      return (
        <div className={`${baseClasses} cursor-not-allowed opacity-70 text-gray-700`}>
          <div className="flex items-center space-x-3">
            {iconGroup}
            <span>{calc.sideMenu}</span>
          </div>
          <span className="text-xs bg-gray-200 text-gray-700 ml-2 px-2 py-0.5 rounded-full">
             SOON
          </span>
        </div>
      );
    }

    // For accessible items
    return (
      <Link
        to={calc.path}
        className={`${baseClasses} ${
          isActive ? 'bg-blue-600 text-white shadow-md' : 'text-gray-700 hover:bg-blue-50'
        }`}
      >
        <div className="flex items-center space-x-3">
          {iconGroup}
          <span>{calc.sideMenu}</span>
        </div>
        <div className="flex gap-2 ml-2">
          {shouldShowBetaPill(calc) && (
            <span className="text-xs bg-blue-600 text-white px-2 py-1 rounded-full">
              BETA
            </span>
          )}
          {shouldShowComingSoonPill(calc) && (
            <span className="text-xs bg-gray-200 text-gray-700 ml-2 px-2 py-1 rounded-full">
              SOON
            </span>
          )}
        </div>
      </Link>
    );
  };

  return (
    <div className="w-68 bg-white shadow-md rounded-md p-3 h-fit">
      <nav>
        <ul className="space-y-2">
          {Object.entries(calculatorConfig)
            .filter(([_, calc]) => !calc.hidden)
            .map(([key, calc]) => (
              <li key={calc.path}>
                {renderMenuItem(calc)}
              </li>
            ))}
        </ul>
      </nav>
    </div>
  );
};

export default SideMenu;